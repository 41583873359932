import type { EcomSiteUidLegacy } from "./global";

export const EMPTY_STRING = "";
export const ENTER_KEY = "Enter";
export const MISSING_PRODUCT_IMAGE_URL = "/static/svg/no_image.svg";
export const SPACE_BAR_KEY = " ";
export const DUMMY_URL = "https://dummy";
export const CART_BASE_PATH = "/checkout";
export const ACCOUNT_BASE_PATH = "/account";
export const QUANTITY_ONE = 1;
export const LOGOUT_URL = "/logout";

export const DEFAULT_PICKUP_READY_HOURS = 2;

export const PageType = {
  FALLBACK: "", // fallback, used when not set
  ACCOUNT: "account",
  BRAND: "brand",
  BRAND_INDEX: "brandIndex",
  BUNDLE: "bundle",
  BUY_GIFT_CARD: "buyGiftCard",
  CAMPAIGN: "campaign",
  CAMPAIGN_HUB: "campaignHub",
  CART: "cart",
  CATEGORY: "category",
  CHECKOUT: "checkout",
  CLUB: "club",
  CONFIRMATION: "confirmation",
  CUSTOMER_SERVICE: "customerservice",
  DIGITAL_MARKETING: "digitalmarketing",
  DIGITAL_RETURNS: "digitalReturns",
  DYNAMIC_TOGGLE: "dynamicToggle",
  ERROR: "error",
  FAQ: "faq",
  GUIDE: "guide",
  GUIDES: "guides",
  HOME: "home",
  LOGIN: "login",
  ORDER: "order",
  ORDER_CONFIRMATION: "order-confirmation",
  PRODUCT: "product",
  QR: "qr",
  REWARD: "reward",
  ROBOTS: "robots",
  SEARCH: "search",
  SESSIONLESS_CHECKOUT: "sessionless-checkout",
  SHARED: "shared",
  SIZE_GUIDE: "size-guide",
  STORE: "store",
  STORE_FINDER: "storeFinder",
  VALIDATION: "validation",
  WORKSHOP: "workshop",
} as const;
export type PageType = (typeof PageType)[keyof typeof PageType];

export const pagePaths = {
  category: "/c/",
  product: "/p/",
  orderConfirmation: "klarna/order-confirmation",
  paymentStatus: "/payment-status",
  checkout: "/checkout",
  orderConfirmationPage: "order-confirmation",
  brandIndex: "/b/brandindex",
  storeFinder: "/store-finder",
  guides: ["/guides", "/tips-och-rad", "/tips", "/vinkit-ja-neuvot"],
  campaignHub: "/digitalcampaign",
  error: "/_error",
};

export const STOCK_STATUS = {
  INSTOCK: "INSTOCK",
  OUTOFSTOCK: "OUTOFSTOCK",
  LOWSTOCK: "LOWSTOCK",
};

export const DefaultPostalCodes = {
  austria: "dummy_value",
  denmark: "1550",
  finland: "00170",
  norway: "0037",
  sweden: "11152",
} as const;

export const MISSING_ACCOUNT_DATA = "missingAccountData";
export const REGISTERED_CUSTOMERS_USER_GROUP = "registeredcustomers";
export const ANONYMOUS_CUSTOMERS_USER_GROUP = "anonymous";
export const ANONYMOUS_CUSTOMERS_USER_GROUP_ALT = "NONE";

export const marketsWithPhysicalStores: EcomSiteUidLegacy[] = [
  "xxl",
  "xxl-fi",
  "xxl-se",
];
export const MAX_NR_OF_PRODUCT_BADGES = 2;
export const INFO_TIMEOUT = 5000;

export const DEFAULT_ORDER_DETAIL_PRODUCT_IMAGE_SIZE = 186;
export const REGULAR_RETURN_PRODUCT_IMAGE_SIZE = 60;
export const SMALL_EXCHANGE_DETAIL_PRODUCT_IMAGE_SIZE = 30;

export const IMAGE_BASE_SOURCE_URL = {
  filespin: "/filespin/",
  sanity: "/content/images/",
};

export const CATEGORY_LEVEL_2 = 2;
export const CATEGORY_LEVEL_3 = 3;

/**
 * @link https://columbus-dc.atlassian.net/wiki/spaces/xxl/pages/330661959/Cookies
 */
export const necessaryCookies = [
  "aws-waf-token",
  "cartDetails",
  "cartId",
  "CookieInformationConsent",
  "sessionKey",
  "xxl_auth",
  "xxl",
];
export const FILTER_SEPARATOR = "|";

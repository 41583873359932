import { useEffect, useState } from "react";
import {
  SANITY_TESTS_MAP,
  DEFAULT_VARIATION_NAME,
  AB_TEST_NAMES,
} from "./constants";
import type { TTestName, TSanityTestName } from "./constants";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";

const TIMEOUT = 200;
const MAX_ATTEMPTS = 3;

const useIsSymplifyLoaded = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    const checkIsSymplifyLoaded = () => {
      if (isNotNullOrUndefined(window.symplify)) {
        setIsLoaded(true);
      } else if (attempts < MAX_ATTEMPTS) {
        setTimeout(
          () => {
            setAttempts((prevAttempts) => prevAttempts + 1);
          },
          TIMEOUT * Math.pow(2, attempts)
        );
      } else {
        log.info(`Failed to load Symplify after ${MAX_ATTEMPTS} attempts`);
      }
    };
    checkIsSymplifyLoaded();
  }, [attempts]);

  return isLoaded;
};

export const useSymplify = (testName: TTestName) => {
  const isLoaded = useIsSymplifyLoaded();
  const [isTestVariant, setIsTestVariant] = useState(false);
  useEffect(() => {
    if (isLoaded) {
      const ap = window.symplify.getActivatedProjects();
      if (ap.length === 0) {
        setIsTestVariant(false);
        return;
      }
      if (Object.values(AB_TEST_NAMES).includes(testName)) {
        setIsTestVariant(
          ap.some(
            (p) =>
              p.projectName.toLowerCase() === testName.toLowerCase() &&
              p.variationName.toLowerCase() ===
                DEFAULT_VARIATION_NAME.toLowerCase()
          )
        );
      }
    }
  }, [isLoaded, testName]);

  return isTestVariant;
};

export const useSymplifyWithSanity = (
  testName: TSanityTestName,
  campaignId?: string
) => {
  const isLoaded = useIsSymplifyLoaded();
  const [isTestVariant, setIsTestVariant] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      const ap = window.symplify.getActivatedProjects();
      if (ap.length === 0) {
        setIsTestVariant(false);
        return;
      }
      if (testName === "HOMEPAGE_SANITY_TEST") {
        setIsTestVariant(
          ap.some((p) =>
            p.projectName
              .toLowerCase()
              .includes(SANITY_TESTS_MAP.HOMEPAGE_SANITY_TEST.PROJECT_NAME)
          )
        );
      } else if (isNotNullOrUndefined(campaignId)) {
        setIsTestVariant(
          ap.some(
            (p) =>
              p.projectName
                .toLowerCase()
                .includes(
                  SANITY_TESTS_MAP.CAMPAIGN_PAGE_SANITY_TEST.PROJECT_NAME
                ) && p.projectName.includes(campaignId)
          )
        );
      }
    }
  }, [campaignId, isLoaded, testName]);

  return isTestVariant;
};

// can this file be moved to Next?
import { hasNoValue, isNotNull, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  BannerWithText,
  BannerWithTextTypeEnum,
  GridBanner,
  GridBannerTypeEnum,
} from "@xxl/content-api";
import type { PriceDisplayData, PricesApi } from "@xxl/frontend-api";
import { color } from "@xxl/theme";
import chunk from "lodash/chunk";
import type { Translate } from "../../contexts/Translations/TranslationsContext";
import type { EcomSiteUidLegacy } from "../../global";
import { getFrontendPriceDisplayData } from "../../utils/PriceDisplay/price-display";
import type { ProductCardDataV2 } from "../../utils/Search/search-helper";
import type { NextJsTranslations } from "../../utils/xxl-translate";
import { translate } from "../../utils/xxl-translate";
import {
  getBackgroundColorFromColorTheme,
  getColorTheme,
  getForegroundColorFromColorTheme,
} from "../Product/product-helper";
import type { Ribbon } from "./types";
import { log } from "@xxl/logging-utils";

type CommonProductBannerPricing = {
  productCode: string;
  prices: {
    selling: {
      price: string;
      label?: string;
    };
    alternate?: {
      price: string;
      label?: string;
    };
    cheapestInRecentPast?: string;
  };
};

export type SmallProductBannerPricing = CommonProductBannerPricing & {
  type:
    | "mediumProductBanner"
    | "smallProductBanner"
    | "twoColumnGridProductBanner"
    | "xsProductBanner";
  ribbon?: Ribbon;
};

export type HighlightedProductBannerPricing = CommonProductBannerPricing & {
  type: "highlightedSmallProductBanner" | "highlightedMediumProductBanner";
  ribbons: {
    price: Ribbon;
    discount?: Ribbon;
  };
};

export type ProductBannerPricing =
  | SmallProductBannerPricing
  | HighlightedProductBannerPricing;

enum BannerSize {
  XS = "xs",
  SMALL = "small",
  MEDIUM = "medium",
}

type Ribbons = {
  productCode: string;
  price: Ribbon;
  discount?: Ribbon;
};

type TypeId = {
  _type?:
    | "fourGridBanners"
    | "TwoColumnBanners"
    | "TwoColumnGrid"
    | "twoColumnGridProductBanner"
    | BannerWithTextTypeEnum
    | GridBannerTypeEnum;
};
export type BannerWithExtendedTypeProperty =
  | BannerWithText
  | (BannerWithText & TypeId)
  | GridBanner
  | (GridBanner & TypeId);

type ProductGridBannerType =
  | "highlightedMediumProductBanner"
  | "highlightedSmallProductBanner"
  | "mediumProductBanner"
  | "smallProductBanner"
  | "twoColumnGridProductBanner"
  | "xsProductBanner";

const isProductBannerType = (
  bannerType: unknown
): bannerType is ProductGridBannerType =>
  isNotNullOrUndefined(bannerType) &&
  (bannerType === "highlightedMediumProductBanner" ||
    bannerType === "highlightedSmallProductBanner" ||
    bannerType === "mediumProductBanner" ||
    bannerType === "smallProductBanner" ||
    bannerType === ("twoColumnGridProductBanner" as GridBannerTypeEnum) ||
    bannerType === "xsProductBanner");

export type PriceDisplayMap = {
  [key: string]: PriceDisplayData;
};

export const fetchPriceDisplays = async (
  productCodes: string[],
  pricesApi: PricesApi
): Promise<PriceDisplayMap[]> => {
  const maxAllowedProductCodesPerRequest = 40; //HARDCODED because we want to use it for Next.js as well, but we still have dependency to Spring
  const uniqueListOfProductCodes = [...new Set(productCodes)];
  const chunksOfProductCodes = chunk(
    uniqueListOfProductCodes,
    maxAllowedProductCodesPerRequest
  );

  try {
    return (
      await Promise.all(
        chunksOfProductCodes.map((chunk) =>
          pricesApi.pricesControllerGetPricesJSONPOST(chunk)
        )
      )
    ).map(({ data }) => data);
  } catch (error) {
    log.error("Error while fetching price displays.");
    return [];
  }
};

export const convertToBannerPricing = (
  priceDisplays: PriceDisplayMap[],
  t: Translate,
  isLoggedIn: boolean,
  siteUid: EcomSiteUidLegacy,
  gridBanners: BannerWithExtendedTypeProperty[] // gridBanners: (GridBanner | BannerWithText)[],
): ProductBannerPricing[] => {
  const productBanners = gridBanners.filter((banner) =>
    isProductBannerType(banner._type)
  );

  return productBanners
    .map((banner) => {
      const { _type, product = {} } = banner;
      const { productCode } = product;

      if (!isProductBannerType(_type)) {
        return null;
      }

      if (hasNoValue(productCode)) {
        log.error(
          `Product with code "${typeof productCode === "string" ? productCode : "unknown"}" is missing price ribbons.`
        );
        return null;
      }

      const priceDisplay =
        priceDisplays.find((display) =>
          isNotNullOrUndefined(display[productCode])
        ) ?? {};
      if (hasNoValue(priceDisplay)) {
        log.error(
          `Product with code "${productCode}" is missing price display data.`
        );
        return null;
      }
      const displayData = priceDisplay[productCode];
      const { otherPrice, otherPriceDisclaimer, priceSplash, salesPrice } =
        getFrontendPriceDisplayData({
          priceDisplay: displayData,
          t,
          isLoggedIn,
          siteUid,
        });

      if (hasNoValue(salesPrice)) {
        log.error(`Product with code "${productCode}" is missing salesPrice.`);
        return null;
      }

      if (
        _type === "highlightedMediumProductBanner" ||
        _type === "highlightedSmallProductBanner"
      ) {
        const ribbons: Ribbons = {
          productCode,
          ...(isNotNullOrUndefined(priceSplash) &&
            isNotNullOrUndefined(banner) && {
              discount: {
                label: priceSplash,
                colors: {
                  background:
                    banner.discountBackgroundColor?.value ?? color.orange.hex,
                  foreground:
                    banner.discountTextColor?.value ?? color.black.hex,
                },
              },
            }),
          price: {
            label: salesPrice.toString(),
            colors: {
              background:
                banner.priceBackgroundColor?.value ?? color.orange.hex,
              foreground: banner.priceTextColor?.value ?? color.black.hex,
            },
          },
        };

        return {
          prices: {
            selling: {
              price: salesPrice.toString(),
            },
            alternate: isNotNullOrUndefined(otherPrice)
              ? {
                  label: otherPriceDisclaimer,
                  price: otherPrice,
                }
              : undefined,
            cheapestInRecentPast: isNotNullOrUndefined(
              banner.additionalPriceLabel
            )
              ? banner.additionalPriceLabel
              : undefined,
          },
          type: _type,
          productCode,
          ribbons,
        };
      }

      if (
        _type === "mediumProductBanner" ||
        _type === "smallProductBanner" ||
        _type === "xsProductBanner" ||
        _type === ("twoColumnGridProductBanner" as GridBannerTypeEnum)
      ) {
        const colorTheme = getColorTheme(displayData);
        const backgroundColor = isNotNullOrUndefined(colorTheme)
          ? getBackgroundColorFromColorTheme(colorTheme)
          : null;
        const foregroundColor = isNotNullOrUndefined(colorTheme)
          ? getForegroundColorFromColorTheme(colorTheme)
          : null;

        return {
          prices: {
            selling: {
              price: salesPrice.toString(),
            },
            ...(isNotNullOrUndefined(otherPrice) && {
              alternate: {
                label: otherPriceDisclaimer,
                price: otherPrice,
              },
            }),
            ...(isNotNullOrUndefined(banner.additionalPriceLabel) && {
              cheapestInRecentPast: banner.additionalPriceLabel,
            }),
          },
          productCode,
          type: _type,
          ...(isNotNullOrUndefined(priceSplash) && {
            ribbon: {
              label: priceSplash,
              colors: {
                background: backgroundColor ?? color.orange.hex,
                foreground: foregroundColor ?? color.black.hex,
              },
            },
          }),
        };
      }

      return null;
    })
    .filter(isNotNull);
};

export const convertToBannerPricingV2 = (
  products: ProductCardDataV2[],
  translations: NextJsTranslations,
  gridBanners: BannerWithExtendedTypeProperty[]
): ProductBannerPricing[] => {
  const productBanners = gridBanners.filter((banner) =>
    isProductBannerType(banner._type)
  );

  return productBanners
    .map((banner) => {
      const { _type, product = {} } = banner;
      const { productCode } = product;

      if (!isProductBannerType(_type)) {
        return null;
      }

      if (hasNoValue(productCode)) {
        log.error(
          `Product with code "${typeof productCode === "string" ? productCode : "unknown"}" is missing price ribbons.`
        );
        return null;
      }

      const foundProduct = products.find(({ code }) => code === productCode);

      if (!isNotNullOrUndefined(foundProduct)) {
        log.error(
          `Product with code "${typeof productCode === "string" ? productCode : "unknown"}" could not be found.`
        );
        return null;
      }

      const {
        price: { alternate, selling },
      } = foundProduct;

      if (
        _type === "highlightedMediumProductBanner" ||
        _type === "highlightedSmallProductBanner"
      ) {
        const ribbons: Ribbons = {
          productCode,
          ...(isNotNullOrUndefined(alternate) && {
            discount: {
              label: alternate.valueFormatted,
              colors: {
                background:
                  banner.discountBackgroundColor?.value ?? color.orange.hex,
                foreground: banner.discountTextColor?.value ?? color.black.hex,
              },
            },
          }),
          price: {
            label: selling.valueFormatted,
            colors: {
              background:
                banner.priceBackgroundColor?.value ?? color.orange.hex,
              foreground: banner.priceTextColor?.value ?? color.black.hex,
            },
          },
        };

        return {
          prices: {
            selling: {
              price: selling.valueFormatted,
            },
            ...(isNotNullOrUndefined(alternate) &&
              alternate.labelType !== "None" && {
                alternate: {
                  label:
                    alternate.labelType === "TranslationKey"
                      ? translate(alternate.translationKey, translations)
                      : alternate.label,
                  price: alternate.valueFormatted,
                },
              }),
            ...(isNotNullOrUndefined(selling.labelType) &&
              selling.labelType !== "None" && {
                cheapestInRecentPast:
                  selling.labelType === "TranslationKey"
                    ? translate(selling.translationKey, translations)
                    : selling.label,
              }),
          },
          type: _type,
          productCode,
          ribbons,
        };
      }

      if (
        _type === "mediumProductBanner" ||
        _type === "smallProductBanner" ||
        _type === "xsProductBanner" ||
        _type === ("twoColumnGridProductBanner" as GridBannerTypeEnum)
      ) {
        const background =
          foundProduct.campaignRibbon?.content?.backgroundColor ??
          color.orange.hex;
        const foreground =
          foundProduct.campaignRibbon?.content?.frontColor ?? color.orange.hex;
        const ribbonLabel = foundProduct.campaignRibbon?.content?.text;

        return {
          prices: {
            selling: {
              price: selling.valueFormatted,
            },
            ...(isNotNullOrUndefined(alternate) &&
              alternate.labelType !== "None" && {
                alternate: {
                  label:
                    alternate.labelType === "TranslationKey"
                      ? translate(alternate.translationKey, translations)
                      : alternate.label,
                  price: alternate.valueFormatted,
                },
              }),
            ...(selling.labelType !== "None" && {
              cheapestInRecentPast:
                selling.labelType === "TranslationKey"
                  ? translate(selling.translationKey, translations)
                  : selling.label,
            }),
          },
          productCode,
          type: _type,
          ...(isNotNullOrUndefined(ribbonLabel) && {
            ribbon: {
              label: ribbonLabel,
              colors: {
                background,
                foreground,
              },
            },
          }),
        };
      }

      return null;
    })
    .filter(isNotNull);
};

export { BannerSize };

import { isNotNullOrUndefined } from "@xxl/common-utils";
import React, { useRef, useState } from "react";
import { resolutionSizes } from "../../config";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { windowAccess } from "../../utils/Window";
import { CartServiceProduct } from "../Cart/Components/CartServiceProduct";
import { Overlay } from "../Cart/Styles/ProductItem.styled";
import { DialogBox } from "../DialogBox";
import { XXLLoader } from "../XXLLoader";
import { ExpandableDescription } from "../Common/ExpandableDescription";
import { GeneralErrorsList } from "./GeneralErrorsList";
import { ServiceProduct } from "./ServiceProduct";

import {
  Content,
  Footer,
  Header,
  ListWrapper,
  ServiceProductsList,
  ShadowBottom,
  ShadowTop,
  ShadowsContainer,
  Title,
  Wrapper,
} from "./ServiceProductsWrapper.styled";
import { UspBar } from "./UspBar";
import { INDEX_OF_RECOMMENDED_SERVICE } from "./constants";
import { useListShadowOnScroll } from "./hooks/useListShadowOnScroll";
import { DialogFooter, VariantServiceProductsList } from "./Variant/styles";
import { VariantServiceProduct } from "./Variant/VariantServiceProduct";
import { Dialog } from "./Variant/Dialog";
import { XxlStack } from "../Common/XxlStack";
import { Text } from "../Text";
import { XxlButton } from "../Common/XxlButton";
import { useServicesContext } from "./ServiceProductsContext";
import { SERVICE_CODE } from "../../utils/Symplify/constants";
import { useSymplify } from "../../utils/Symplify/hooks";
import { useBreakpoint } from "../../hooks/useBreakpoint/useBreakpoint";

/**
 * These hardcoded values are used for the AB test
 * @link https://xxlsports.atlassian.net/browse/XD-14524
 */

const SERVICE_ITEMS = {
  [SERVICE_CODE.IMBOX]: [
    "Bevarar skornas ursprungliga andningsförmåga",
    "Professionell impregnering",
    "Miljövänlig och skonsam",
    "Kraftigt vatten- och smutsavvisande",
    "Redo att användas vid leverans",
    "Vid byte av modell eller storlek impregneras dina nya skor",
  ],
  [SERVICE_CODE.BIKE]: [
    "25% billigare vid köp av cykel",
    "Justering av växlar och bromsar",
    "Kontroll av nav och ekerspänning",
    "Smörjning av kedjan",
    "Åtdragning av skruvar, sadel, styre, pedaler och styrstam",
  ],
};

const DESCRTIPTION_TEXT = {
  [SERVICE_CODE.IMBOX]:
    "Vill du skydda dina nya skor med en miljövänlig och skonsam impregnering som gör dem smuts- och vattenavvisande i upp till 12 veckor?",
  [SERVICE_CODE.BIKE]:
    "För att din nya cykel ska fungera optimalt i många år rekommenderar vi att du servar din cykel regelbundet. Efter 20-30 mil eller 4-6 månader (vad som kommer först) bör du lämna in din cykel för en genomgång och justering av rörliga komponenter. Samtliga av våra XXL-varuhus har cykelverkstäder som står redo för dig och din cykel!",
};

type BaseServiceProductWrapperProps = {
  actionInProgress: boolean;
  dialogBoxHeaderText: string;
  isModalOpen: boolean;
  onDialogBoxClose: () => void;
  renderFooterButtons: () => JSX.Element;
  setActionInProgress: (value: boolean) => void;
  category?: string;
  dialogBoxTestId?: string;
};

type ServiceProductsWrapperProps = BaseServiceProductWrapperProps & {
  showOverlayOnActionInProgress?: undefined;
};

type ServiceCartProductsWrapperProps = BaseServiceProductWrapperProps & {
  showOverlayOnActionInProgress?: boolean;
};

const ServiceProductsWrapper = ({
  category,
  onDialogBoxClose,
  renderFooterButtons,
  dialogBoxTestId,
  dialogBoxHeaderText,
  isModalOpen,
  actionInProgress,
  setActionInProgress,
  showOverlayOnActionInProgress,
}: ServiceProductsWrapperProps | ServiceCartProductsWrapperProps) => {
  const {
    _sharedData: { serviceDescriptions },
  } = windowAccess();
  const { t } = useTranslations();
  const bp = useBreakpoint();
  const isMobile = bp === "mobile";
  const listViewportRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { state } = useServicesContext();
  const { isBottomShadowVisible, isTopShadowVisible } = useListShadowOnScroll({
    listRef,
    listViewportRef,
    scrollPosition,
  });
  const descriptionObject = serviceDescriptions?.find(({ categories }) =>
    categories.includes(category ?? "")
  );
  const serviceGroupTitle =
    descriptionObject?.title ?? t("services.popup.category.title.default");
  const serviceGroupDescription =
    descriptionObject?.description ??
    t("services.popup.category.description.default");

  const isTestVariant = useSymplify("cart-services-add-on");

  if (state.services?.productList.length === 1 && isTestVariant) {
    const item = state.services.productList[0];
    return (
      <Dialog
        open={isModalOpen}
        fullScreen={isMobile}
        maxWidth="xs"
        onClose={onDialogBoxClose}
        heading={dialogBoxHeaderText}
      >
        <XxlStack
          sx={{
            height: "100%",
            maxWidth: isMobile ? "100%" : "495px",
          }}
        >
          {isNotNullOrUndefined(state.generalErrors) && (
            <GeneralErrorsList errors={state.generalErrors} />
          )}
          <ListWrapper>
            {showOverlayOnActionInProgress === true && actionInProgress && (
              <Overlay>
                <XXLLoader />
              </Overlay>
            )}
            <XxlStack px={"12px"} py={"24px"}>
              <Text typography="baseBold">{serviceGroupTitle}</Text>
              <Text>
                {DESCRTIPTION_TEXT[item.code as keyof typeof DESCRTIPTION_TEXT]}
              </Text>
              {isNotNullOrUndefined(state.services) &&
                !Array.isArray(state.services) &&
                isNotNullOrUndefined(state.services.productList) && (
                  <VariantServiceProductsList>
                    <VariantServiceProduct
                      item={item}
                      actionInProgress={actionInProgress}
                      setActionInProgress={setActionInProgress}
                      onClose={onDialogBoxClose}
                      serviceTextList={
                        SERVICE_ITEMS[item.code as keyof typeof SERVICE_ITEMS]
                      }
                    />
                  </VariantServiceProductsList>
                )}
            </XxlStack>
          </ListWrapper>
        </XxlStack>

        <DialogFooter>
          <XxlButton
            variant="outlined"
            onClick={onDialogBoxClose}
            disabled={actionInProgress}
          >
            {t("sorts.close.button.text")}
          </XxlButton>
        </DialogFooter>
      </Dialog>
    );
  }

  return (
    <DialogBox
      isDialogBoxOpen={isModalOpen}
      hasPadding={false}
      dialogBoxSize={"md"}
      handleDialogBoxClosing={onDialogBoxClose}
      contentStyle={{ overflow: "hidden" }}
      testId={dialogBoxTestId}
    >
      <Header>{dialogBoxHeaderText}</Header>
      {isNotNullOrUndefined(state.generalErrors) && (
        <GeneralErrorsList errors={state.generalErrors} />
      )}
      <ListWrapper ref={listViewportRef}>
        {showOverlayOnActionInProgress === true && actionInProgress && (
          <Overlay>
            <XXLLoader />
          </Overlay>
        )}
        <ShadowsContainer>
          <ShadowTop isVisible={isTopShadowVisible} />
          <ShadowBottom isVisible={isBottomShadowVisible} />
        </ShadowsContainer>
        <Wrapper
          onScroll={({ target }) => {
            const { scrollTop } = target as HTMLDivElement;
            setScrollPosition(scrollTop);
          }}
        >
          <Content ref={listRef}>
            <Title>{serviceGroupTitle}</Title>

            <ExpandableDescription
              description={serviceGroupDescription}
              numberOfLinesToShow={2}
              minResolutionToStopClamping={resolutionSizes.mobile}
            />
            {isNotNullOrUndefined(state.services) &&
              !Array.isArray(state.services) &&
              isNotNullOrUndefined(state.services.productList) &&
              state.services.productList.length > 0 && (
                <ServiceProductsList
                  numberOfItems={state.services.productList.length}
                >
                  {state.services.productList.map((item, index) => (
                    <ServiceProduct
                      key={index}
                      item={item}
                      handleChange={setActionInProgress}
                      actionInProgress={actionInProgress}
                      isRecommended={index === INDEX_OF_RECOMMENDED_SERVICE}
                      category={category}
                    />
                  ))}
                </ServiceProductsList>
              )}
            {Array.isArray(state.services) && (
              <ServiceProductsList numberOfItems={state.services.length}>
                {state.services.map((item, index) => (
                  <CartServiceProduct
                    key={`${item.title}-${index}`}
                    item={item}
                    handleChange={setActionInProgress}
                    actionInProgress={actionInProgress}
                    isRecommended={index === INDEX_OF_RECOMMENDED_SERVICE}
                    category={category}
                  />
                ))}
              </ServiceProductsList>
            )}
          </Content>
        </Wrapper>
      </ListWrapper>
      <Footer>
        {renderFooterButtons()}
        <UspBar />
      </Footer>
    </DialogBox>
  );
};

export { ServiceProductsWrapper };

import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { ArrayOfBundleData } from "@xxl/pim-api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useRelinkValue } from "react-relink";
import { useApiClients } from "../../contexts/ApiClients";
import { useSessionSource } from "../../contexts/Session";
import { useSharedData } from "../../contexts/SharedData";
import { useTracking } from "../../contexts/Tracking";
import {
  type CartItemIdInput,
  type CollectStoreWarningType,
} from "../../generated/graphql-code-generator";
import type {
  AddBundleToCartEventData,
  AddConfigurableProductToCartEventData,
  AddToCartEventData,
  BundleConfigurationData,
  CartEventData,
  EcomSiteUidLegacy,
  XXLAddToCartErrorPayload,
} from "../../global";
import { useClient } from "../../hooks/useClient/useClient";
import type { TranslationKey } from "../../translations";
import { windowAccess } from "../../utils/Window";
import * as XxlEvent from "../../utils/xxl-event";
import { isBundlePage, isProductPage } from "../../utils/xxl-page-type";
import { showStickyHeader } from "../../utils/xxl-toggle-sticky-header";
import type {
  CrossSalesProductWithFixedProductType,
  ProductAddedToCartData,
} from "../CrossSales";
import type { GetProductsProps } from "../CrossSales/API/CrossSalesAPI";
import { CrossSales } from "../CrossSales/CrossSales";
import { CART_PAYMENT_STATUS } from "../PaymentStatus/payment-status-helper";
import { PIECE_UNIT } from "../Product/product-helper";
import { checkIfAbandonedCartMode } from "../Reward/Login/Login.helper";
import { SignupModalWrapper } from "../Reward/SignUp/SignupModalWrapper";
import { ServiceProducts } from "../Services";
import type { ServicesResponse } from "../Services/ServiceProductsContext";
import { XXLLoader } from "../XXLLoader";
import {
  addCollectableProductsToCart,
  addConfigurableProductToCart,
  addToCart,
  ERROR_RESPONSE_STATUS,
  getCart,
  SUCCESS_RESPONSE_STATUS,
} from "./Api/CartAPI";
import { addBundleWithEanToCart } from "./Api/CartBundleAPI";
import type {
  DisplayCart,
  GenericGraphQLError,
  UpdateQuantityGraphQLError,
} from "./Api/types";
import {
  getCrossSalesProducts,
  getServiceProducts,
  hasOutOfStockError,
} from "./cart-page-helper";
import { CartContent } from "./CartContent";
import {
  ADD_TO_CART,
  ADD_TO_CART_SUCCESS,
  CART_CONTENT_EDITING_LOCK,
  CART_CONTENT_EDITING_UNLOCK,
  CART_EDITION_START,
  CART_EDITION_STOP,
  CART_REQUEST_SUCCESS,
  COLLECT_SUCCESS,
  COLLECT_WARNING_CLEAR,
  COLLECT_WARNING_DISPLAY,
  ENABLE_CART_API_REQUEST,
  HAS_MINI_CART,
  isKlarnaCheckoutSnippetLoaded,
  isWalley,
  SET_CART_COUNT,
  UPDATE_CART_CONTENT,
  UPDATE_CART_COUNT,
  useCartContext,
} from "./CartState";
import { CollectWarningOnPage } from "./Components/CollectWarningOnPage";
import { WalleyCheckoutEvents } from "./constants";
import { useSetCartCountEventListener } from "./hooks/useSetCartCountEventListener";
import { MiniCartContent } from "./MiniCartContent";
import { initializeCheckout } from "./Services/checkout";
import {
  setBundledProducts,
  setBundledProductsConfigurations,
  SINGLE_QUANTITY_NUMBER,
} from "./Services/setBundledProducts";
import { setBundleProductsWithPrint } from "./Services/setBundleWithPrint";
import {
  handleTrackingAddProductToCart,
  handleTrackingCartUpdatedInCheckout,
} from "./Services/tracking";
import { updateCheckoutSnippet } from "./Services/updateCartQuantity";
import { LoginModalWrapper } from "../Reward/Login/LoginModalWrapper";
import { log } from "@xxl/logging-utils";

const EMPTY_STRING = "";
const PREVENT_INITIAL_LOAD_CALL_TIMEOUT = 1000;

type CartProps = {
  isMiniCart: boolean;
  isTeamAdmin?: string;
  isTeamsalesAdmin?: string;
};

type ClickAndCollectWarning = {
  stock: number;
  type: CollectStoreWarningType;
};

export const Cart: React.FunctionComponent<CartProps> = ({
  isMiniCart,
  isTeamAdmin,
  isTeamsalesAdmin,
}) => {
  const { state, dispatch } = useCartContext();
  const [productData, setProductData] = useState<ProductAddedToCartData | null>(
    null
  );

  const {
    isReactApp,
    data: {
      configuration,
      featureToggles: {
        toggle_cross_sales = false,
        toggle_products_as_package_quantity,
      },
      pageType,
      siteUid,
    },
  } = useSharedData();
  const isClient = useClient();
  const productListName =
    windowAccess()._sharedData.gtmData.productListName ?? "";
  const trackers = useTracking();
  const { pimApi } = useApiClients();

  const [crossSalesProducts, setCrossSalesProducts] = useState<
    CrossSalesProductWithFixedProductType[]
  >([]);
  const isLoggedIn = useRelinkValue(useSessionSource);
  const [hideCartContent, setHideCartContent] = useState(false);
  const [initializedCheckout, setInitializedCheckout] = useState(false);

  useEffect(() => {
    if (isMiniCart) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const paymentStatus = params.get(CART_PAYMENT_STATUS.parameter);

    if (
      paymentStatus === CART_PAYMENT_STATUS.value.cancelled ||
      paymentStatus === CART_PAYMENT_STATUS.value.error
    ) {
      return;
    }
  }, [isMiniCart]);

  useEffect(() => {
    const isAbandonedCartMode = checkIfAbandonedCartMode();
    setHideCartContent(isAbandonedCartMode && !isLoggedIn && !isMiniCart);
  }, [isLoggedIn, isMiniCart]);

  const [serviceResponse, setServiceResponse] =
    useState<ServicesResponse | null>(null);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [cartEntryItemId, setCartEntryItemId] = useState<CartItemIdInput>();
  const [crossSalesElement, setCrossSalesElement] =
    useState<HTMLElement | null>(null);
  const [servicesElement, setServicesElement] = useState<HTMLElement | null>(
    null
  );
  const [pimConfigurations, setPimConfigurations] =
    useState<ArrayOfBundleData>();
  const [isCrossSalesOpen, setIsCrossSalesOpen] = useState(false);
  const [clickAndCollectWarning, setClickAndCollectWarning] =
    useState<ClickAndCollectWarning | null>(null);
  const [isInitialCall, setIsInitialCall] = useState(true);
  const [updateCheckoutTracking, setUpdateCheckoutTracking] = useState(false);

  const handleAdditionalSales = useCallback(
    async ({
      categoryCode,
      styleCode,
      price,
      isClickAndCollect,
    }: {
      categoryCode: string;
      styleCode: string;
      price?: string;
      isClickAndCollect: boolean;
    }) => {
      const getProductsProps: GetProductsProps = {
        basePath: configuration.recommendationsApi.basePath,
        siteUid,
        category: categoryCode,
        product: styleCode,
        price,
      };

      if (isClickAndCollect) {
        setCrossSalesProducts([]);
        setServiceResponse(null);
        return;
      }

      try {
        const [_crossSalesProducts, _serviceProducts] = await Promise.all([
          toggle_cross_sales
            ? getCrossSalesProducts(getProductsProps)
            : Promise.resolve([]),
          getServiceProducts(getProductsProps),
        ]);

        setCrossSalesProducts(_crossSalesProducts ?? []);
        setServiceResponse(_serviceProducts);

        if (_serviceProducts !== null) {
          setIsServicesOpen(true);
        } else if (_crossSalesProducts !== null) {
          setIsCrossSalesOpen(true);
        }
      } catch (error) {
        log.error("Failed to fetch additional products.", error);
        setCrossSalesProducts([]);
        setServiceResponse(null);
      }
    },
    [configuration.recommendationsApi.basePath, siteUid, toggle_cross_sales]
  );

  useEffect(() => {
    setCrossSalesElement(document.getElementById("js-react-cart-cross-sales"));
    setServicesElement(document.getElementById("js-react-cart-services"));
  }, []);
  const { updateCartContent, miniCartCounter, isOnlyMiniCartCount } = state;
  const isTeamAdminBoolean = isTeamAdmin === "true";

  const isProductOrBundlePage =
    (isProductPage() || isBundlePage()) && servicesElement !== null;

  const getConfiguration = useCallback(
    async (siteId: EcomSiteUidLegacy): Promise<ArrayOfBundleData> => {
      try {
        const bundleCode = windowAccess()._sharedData.bundleCode ?? "";
        if (bundleCode === "") {
          throw new Error(
            "Can't request bundle configuration, bundleCode is missing"
          );
        }

        const pimDataResult = await pimApi.getBundles(siteId, bundleCode);

        const configurations = pimDataResult.data;

        setPimConfigurations(configurations);
        return configurations;
      } catch (err) {
        log.error("Cannot get pim data", err);
        return [];
      }
    },
    [pimApi]
  );

  const { category, productSize, parentKey } = isProductOrBundlePage
    ? servicesElement.dataset
    : {
        category: "",
        productSize: "",
        parentKey: "",
      };

  const handleGraphQLErrors = (
    hasOutOfStockErrors: boolean,
    hasInvalidQuantityErrors = false
  ): void => {
    const nonGenericError = hasOutOfStockErrors || hasInvalidQuantityErrors;

    dispatch({
      type: SET_CART_COUNT,
      payload: { count: miniCartCounter ?? 0 },
    });
    dispatch({ type: CART_EDITION_STOP });
    const errorWrappers = document.querySelectorAll(
      ".js-product__error-message"
    );
    const errorTextStock = document.querySelectorAll(
      ".js-product__error-no-stock"
    );
    const errorTextInvalidQuantity = document.querySelectorAll(
      ".js-product__error-invalid-quantity"
    );
    const errorTextGeneric = document.querySelectorAll(
      ".js-product__error-failure"
    );
    const addToCartButton = document.querySelector(".js-cart__add");
    errorWrappers.forEach((item) => {
      item.classList.add("product__error-message--visible");
    });
    errorTextStock.forEach((item) => {
      item.classList.toggle(
        "product__error-message-text--visible",
        hasOutOfStockErrors
      );
    });
    errorTextInvalidQuantity.forEach((item) => {
      item.classList.toggle(
        "product__error-message-text--visible",
        hasInvalidQuantityErrors
      );
    });
    errorTextGeneric.forEach((item) => {
      item.classList.toggle(
        "product__error-message-text--visible",
        !nonGenericError
      );
    });
    addToCartButton?.classList.toggle(
      "product__button-disable",
      nonGenericError
    );

    const getTranslationKey = (): TranslationKey => {
      if (hasOutOfStockErrors || hasInvalidQuantityErrors) {
        return "product.details.add.to.cart.not.enough.stock";
      }

      return "product.details.add.to.cart.failure";
    };
    XxlEvent.dispatchEvent<XXLAddToCartErrorPayload>(
      XxlEvent.XXL_ADD_TO_CART_ERROR,
      {
        errorTranslationKey: getTranslationKey(),
      }
    );
  };

  const clearErrors = (): void => {
    const errorWrappers = document.querySelectorAll(
      ".js-product__error-message"
    );
    const errorTextStock = document.querySelectorAll(
      ".js-product__error-no-stock"
    );
    const errorTextGeneric = document.querySelectorAll(
      ".js-product__error-failure"
    );
    errorWrappers.forEach((item) => {
      item.classList.remove("product__error-message--visible");
    });
    errorTextStock.forEach((item) => {
      item.classList.remove("product__error-message-text--visible");
    });
    errorTextGeneric.forEach((item) => {
      item.classList.remove("product__error-message-text--visible");
    });
  };

  useEffect(() => {
    const onCartCountChange = (event: CustomEvent<CartEventData>) => {
      const { count } = event.detail;
      if (count === miniCartCounter) {
        return;
      }

      dispatch({
        type: CART_EDITION_START,
      });

      if (count === undefined) {
        if (isOnlyMiniCartCount === true) {
          dispatch({
            type: ENABLE_CART_API_REQUEST,
          });
        }
        dispatch({
          type: UPDATE_CART_COUNT,
        });
      } else {
        dispatch({
          type: SET_CART_COUNT,
          payload: { count },
        });
        dispatch({
          type: CART_EDITION_STOP,
        });
      }
    };

    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_CART_UPDATE,
      onCartCountChange
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_CART_UPDATE,
        onCartCountChange
      );
    };
  }, [miniCartCounter, isOnlyMiniCartCount]);

  const _addToCart = async (addToCartEvent: AddToCartEventData) => {
    dispatch({
      type: ADD_TO_CART,
    });
    try {
      dispatch({
        type: CART_EDITION_START,
      });
      const {
        $productBaseElement,
        categoryCode,
        eanCode,
        onError,
        onSuccess,
        quantity,
        salesPrice,
        storeId,
        styleCode,
        unit,
        ticket,
      } = addToCartEvent;
      const isClickAndCollect = storeId !== undefined;

      if (onSuccess === undefined && $productBaseElement !== undefined) {
        // animates the add to cart button
        windowAccess().AnimateElement?.setLoaderAnimation($productBaseElement, {
          isLoading: true,
        });
      }
      const unitWithPackagesToggleValue =
        toggle_products_as_package_quantity && isNotNullOrUndefined(unit)
          ? unit
          : PIECE_UNIT;

      const response = isClickAndCollect
        ? await addCollectableProductsToCart(
            eanCode,
            quantity,
            storeId,
            configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
            configuration.amplifyConfig.aws_appsync_apiKey
          )
        : await addToCart(
            [{ ean: eanCode, quantity, unit: unitWithPackagesToggleValue }],
            configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
            configuration.amplifyConfig.aws_appsync_apiKey
          );

      if ($productBaseElement !== undefined) {
        windowAccess().AnimateElement?.setLoaderAnimation($productBaseElement, {
          isLoading: false,
        });
      }

      const { status, data } = response;
      const responseData =
        isNotNullOrUndefined(data.data) && "addProductsToCart" in data.data
          ? data.data.addProductsToCart
          : data.data?.addCollectableProductsToCart;
      const itemsCount = responseData?.totals.itemsCount ?? 0;

      if (
        status === SUCCESS_RESPONSE_STATUS &&
        itemsCount !== miniCartCounter
      ) {
        const collectWarning =
          responseData?.items.reduce(
            (acc, { collectStore }) => {
              const { warning } = collectStore ?? {};
              if (isNotNullOrUndefined(warning)) {
                acc = warning;
              }

              return acc;
            },
            null as ClickAndCollectWarning | null
          ) ?? null;

        dispatch({
          type: COLLECT_WARNING_CLEAR,
        });

        if (collectWarning !== null && isClickAndCollect) {
          setClickAndCollectWarning(collectWarning);
          dispatch({
            type: COLLECT_WARNING_DISPLAY,
          });
        }
        // todo remove window
        windowAccess().AnimateElement?.toggleAnimation({
          isActive: true,
          timeout: { set: false },
        });
        const addedItem = responseData?.items.find(
          ({ ean }) => ean === eanCode
        );
        setCartEntryItemId(addedItem?.itemId);

        if (addedItem !== undefined) {
          handleTrackingAddProductToCart(
            addToCartEvent,
            addToCartEvent.salesPrice.toString(),
            addToCartEvent.priceType ?? "",
            productListName,
            trackers,
            ticket
          );
        }

        dispatch({
          type: SET_CART_COUNT,
          payload: { count: itemsCount },
        });
        if (isClickAndCollect) {
          dispatch({
            type: COLLECT_SUCCESS,
          });
        } else {
          dispatch({
            type: ADD_TO_CART_SUCCESS,
          });
        }

        void handleAdditionalSales({
          categoryCode,
          styleCode,
          price: salesPrice.toString(),
          isClickAndCollect,
        });

        if (onSuccess !== undefined) {
          onSuccess();
        }
      } else if (status === ERROR_RESPONSE_STATUS) {
        const hasInvalidQuantityErrors =
          data.errors?.some(
            (err) =>
              "errorType" in err &&
              (err as UpdateQuantityGraphQLError).errorType ===
                "INVALID_QUANTITY"
          ) ?? false;

        if (onError !== undefined) {
          onError();
        }

        handleGraphQLErrors(
          hasOutOfStockError(data.errors as GenericGraphQLError[]),
          hasInvalidQuantityErrors
        );
      }
      windowAccess().AnimateElement?.toggleAnimation({
        isActive: false,
        timeout: { set: true, length: 3000 },
      });
    } catch (err) {
      log.error("cannot add to cart", err);
    } finally {
      dispatch({
        type: CART_EDITION_STOP,
      });
    }
  };

  const _addBundleToCart = async (
    bundleConfigurationData: BundleConfigurationData,
    addToCartEvent: AddBundleToCartEventData,
    pimData?: ArrayOfBundleData
  ) => {
    clearErrors();
    const { $clickedButtonElement, isBundleWithPrintConfig, bundleName } =
      addToCartEvent;

    try {
      dispatch({
        type: CART_EDITION_START,
      });
      dispatch({
        type: "ADD_TO_CART",
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      showStickyHeader();

      windowAccess().Cart?.animateAddToCartLoading(true, $clickedButtonElement);
      if (isBundleWithPrintConfig) {
        const { members, bundleEan } = bundleConfigurationData;
        if (pimData !== undefined) {
          const bundledProducts = setBundleProductsWithPrint(members, pimData);

          const response = await addBundleWithEanToCart(
            bundleEan,
            bundledProducts,
            [],
            configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
            configuration.amplifyConfig.aws_appsync_apiKey
          );

          const { status, data } = response;

          if (
            status === SUCCESS_RESPONSE_STATUS &&
            data.data?.addBundleProductsToCart !== undefined
          ) {
            const items = data.data.addBundleProductsToCart.items;

            const sameEanBundlesArray = items.filter(
              (item) => item.ean === String(bundleEan)
            );
            const sameBundlesIds = sameEanBundlesArray.map(
              (item) => item.itemId.id
            );
            const highestBundleId = Math.max(...sameBundlesIds);
            const addedBundle = sameEanBundlesArray.find(
              ({ itemId }) => itemId.id === highestBundleId
            );

            if (addedBundle !== undefined) {
              window.Tracking.addToGiosgCart({
                productCode: window.BundleGlobal.bundleDataJSONConfig.bundle,
                productName: bundleName,
                quantity: addedBundle.quantity.quantity,
              });
              window.Eventstream.addBundleToCart({
                products: bundledProducts,
                bundle: bundleEan,
              });
            }

            dispatch({
              type: SET_CART_COUNT,
              payload: {
                count: data.data.addBundleProductsToCart.totals.itemsCount,
              },
            });
          } else if (status === ERROR_RESPONSE_STATUS) {
            const hasOutOfStockErrors =
              data.errors?.some(
                (err) =>
                  "errorType" in err &&
                  (err as UpdateQuantityGraphQLError).errorType ===
                    "OUT_OF_STOCK"
              ) ?? false;

            handleGraphQLErrors(hasOutOfStockErrors);
          }
        } else {
          await new Promise((resolve) => setTimeout(resolve, 100)); // can't dispatch CART_EDITION_START and CART_EDITION_STOP sequentially
          handleGraphQLErrors(false);
        }
      } else {
        const { bundle, bundleEan, groups } = bundleConfigurationData;
        const bundledProducts = setBundledProducts(groups);
        const bundleConfigurations = setBundledProductsConfigurations(groups);
        const response = await addBundleWithEanToCart(
          bundleEan,
          bundledProducts,
          bundleConfigurations,
          configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
          configuration.amplifyConfig.aws_appsync_apiKey
        );
        const { status, data } = response;
        const itemsCount =
          data.data?.addBundleProductsToCart.totals.itemsCount ?? 0;
        const requestWasSuccessful =
          status === SUCCESS_RESPONSE_STATUS && itemsCount !== miniCartCounter;

        if (requestWasSuccessful) {
          const items = data.data?.addBundleProductsToCart.items;

          const sameEanBundlesArray =
            items?.filter((item) => item.ean === String(bundleEan)) ?? [];
          const sameBundlesIds = sameEanBundlesArray.map(
            (item) => item.itemId.id
          );
          const highestBundleId = Math.max(...sameBundlesIds);
          const addedBundle = sameEanBundlesArray.find(
            ({ itemId }) => itemId.id === highestBundleId
          );
          if (addedBundle !== undefined) {
            setCartEntryItemId(addedBundle.itemId);
            window.Tracking.addToGiosgCart({
              productCode: window.BundleGlobal.bundleDataJSONConfig.bundle,
              productName: bundleName,
              quantity: SINGLE_QUANTITY_NUMBER,
            });
            window.Eventstream.addBundleToCart({
              products: bundledProducts,
              bundle: bundleEan,
            });
          }
          dispatch({
            type: SET_CART_COUNT,
            payload: { count: itemsCount },
          });

          const bundleCategory =
            document.getElementById("js-react-cart-services")?.dataset
              .category ?? EMPTY_STRING;
          if (bundleCategory !== EMPTY_STRING) {
            void handleAdditionalSales({
              categoryCode: bundleCategory,
              isClickAndCollect: false,
              styleCode: bundle,
            });
          }
        } else if (status === ERROR_RESPONSE_STATUS) {
          handleGraphQLErrors(
            hasOutOfStockError(data.errors as GenericGraphQLError[])
          );
        } else {
          handleGraphQLErrors(false);
        }
      }
    } catch (err) {
      log.error("Cannot add bundle to cart", err);
    } finally {
      dispatch({
        type: CART_EDITION_STOP,
      });
      windowAccess().AnimateElement?.toggleAnimation({
        isActive: true,
        timeout: { set: false },
      });

      windowAccess().Cart?.animateAddToCartLoading(
        false,
        $clickedButtonElement
      );
    }
  };

  useEffect(() => {
    if (windowAccess()._sharedData.isBundleWithPrint === true) {
      void getConfiguration(siteUid);
    }
  }, [getConfiguration, siteUid]);

  useEffect(() => {
    const onAddToCartChange = ({ detail }: CustomEvent<AddToCartEventData>) => {
      const {
        brandName,
        productName,
        salesPrice,
        salesPriceFormatted,
        sizeName,
        imageUrl,
      } = detail;

      void _addToCart(detail);

      setProductData({
        brand: brandName ?? "",
        imageUrl,
        name: productName,
        salesPrice,
        salesPriceFormatted,
        size: sizeName ?? "",
      });
    };

    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_ADD_TO_CART,
      onAddToCartChange
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_ADD_TO_CART,
        onAddToCartChange
      );
    };
  });

  useEffect(() => {
    const addBundleToCartChange = (
      event: CustomEvent<AddBundleToCartEventData>
    ) => {
      void _addBundleToCart(
        window.BundleGlobal.bundleDataJSONConfig,
        event.detail,
        pimConfigurations
      );
    };

    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_ADD_BUNDLE_TO_CART,
      addBundleToCartChange
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_ADD_BUNDLE_TO_CART,
        addBundleToCartChange
      );
    };
  }, [pimConfigurations]);

  const lockCart = () =>
    dispatch({
      type: CART_CONTENT_EDITING_LOCK,
    });

  const unlockCart = () =>
    dispatch({
      type: CART_CONTENT_EDITING_UNLOCK,
    });

  useEffect(() => {
    if (isWalley(state.paymentProvider)) {
      document.addEventListener(WalleyCheckoutEvents.locked, lockCart);
      document.addEventListener(WalleyCheckoutEvents.unlocked, unlockCart);
    }

    return () => {
      document.removeEventListener(WalleyCheckoutEvents.locked, lockCart);
      document.removeEventListener(WalleyCheckoutEvents.unlocked, unlockCart);
    };
  }, [state.paymentProvider]);

  const _addConfigurableProductToCart = async (
    event: AddConfigurableProductToCartEventData
  ) => {
    dispatch({
      type: ADD_TO_CART,
    });
    try {
      dispatch({
        type: CART_EDITION_START,
      });
      const response = await addConfigurableProductToCart(event);
      const {
        data: { data },
        status,
      } = response;
      const itemsCount =
        data?.addConfigurableProductsToCart.totals.itemsCount ?? 0;
      const { parentEan } = event;
      const addedItem = data?.addConfigurableProductsToCart.items.find(
        ({ ean }) => ean === parentEan
      );
      const {
        brandName,
        imageUrl,
        name,
        salesPrice,
        salesPriceFormatted,
        sizeName,
      } = event;

      setCartEntryItemId(addedItem?.itemId);
      setProductData({
        brand: brandName,
        imageUrl,
        name,
        salesPrice,
        salesPriceFormatted,
        size: sizeName,
      });

      if (
        status === SUCCESS_RESPONSE_STATUS &&
        itemsCount !== miniCartCounter
      ) {
        dispatch({
          type: SET_CART_COUNT,
          payload: { count: itemsCount },
        });

        const { categoryCode, styleCode, price, isClickAndCollect } = event;

        void handleAdditionalSales({
          categoryCode,
          styleCode,
          price: price.toString(),
          isClickAndCollect,
        });
      }
    } catch (err) {
      log.error(
        `cannot add the configurable product ${JSON.stringify(event)} to cart`,
        err
      );
    } finally {
      dispatch({
        type: CART_EDITION_STOP,
      });
    }
  };

  useEffect(() => {
    const onAddConfigurableProductToCartChange = (
      event: CustomEvent<AddConfigurableProductToCartEventData>
    ) => {
      void _addConfigurableProductToCart(event.detail);
    };
    XxlEvent.addXXLEventListener(
      XxlEvent.type.XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART,
      onAddConfigurableProductToCartChange
    );

    return () => {
      XxlEvent.removeXXLEventListener(
        XxlEvent.type.XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART,
        onAddConfigurableProductToCartChange
      );
    };
  }, []);

  useEffect(() => {
    // Sometimes `window.Checkout` is undefined on next-js-app client side. In this case it must be initialized again
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window.Checkout === undefined) {
      window.Checkout = initializeCheckout(isLoggedIn);
      setInitializedCheckout(true);
    }
  }, []);

  useEffect(() => {
    if (initializedCheckout) {
      window.Checkout.initialize();
      setInitializedCheckout(false);
    }
  }, [initializedCheckout]);

  useEffect(() => {
    const _getCart = async (): Promise<void> => {
      try {
        const cartResponse = await getCart(
          configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
          configuration.amplifyConfig.aws_appsync_apiKey,
          pageType
        );
        if (cartResponse.status === SUCCESS_RESPONSE_STATUS && !isMiniCart) {
          setUpdateCheckoutTracking(true);
          if (
            (isKlarnaCheckoutSnippetLoaded() ||
              isWalley(state.paymentProvider)) &&
            (cartResponse.data.data?.cart?.items.length ?? 0) > 0 &&
            !isInitialCall
          ) {
            void updateCheckoutSnippet(
              dispatch,
              configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
              configuration.amplifyConfig.aws_appsync_apiKey,
              state.customerTypeOptions,
              state.customerType
            );
          }
        }

        dispatch({
          type: CART_REQUEST_SUCCESS,
          payload: { data: cartResponse.data },
        });
        if (isMiniCart) {
          dispatch({
            type: HAS_MINI_CART,
          });
        }
        dispatch({
          type: CART_EDITION_STOP,
        });
      } catch (err) {
        log.error("Cannot get cart data", err);
      }
    };
    if (updateCartContent === true && isOnlyMiniCartCount === false) {
      void _getCart();
    }
  }, [
    configuration.amplifyConfig.aws_appsync_apiKey,
    configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
    isMiniCart,
    isOnlyMiniCartCount,
    updateCartContent,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialCall(false);
    }, PREVENT_INITIAL_LOAD_CALL_TIMEOUT);
  }, []);

  const getDisplayCartData = (
    cartData: DisplayCart | undefined
  ): DisplayCart | boolean => {
    if (cartData !== undefined) {
      return cartData;
    }
    return false;
  };

  const displayCart = useMemo(
    () => getDisplayCartData(state.displayCart),
    [state.displayCart]
  );

  useEffect(() => {
    if (
      typeof displayCart !== "boolean" &&
      !isMiniCart &&
      updateCheckoutTracking
    ) {
      setUpdateCheckoutTracking(false);
      handleTrackingCartUpdatedInCheckout(displayCart, trackers);
    }
  }, [displayCart]);

  useEffect(() => {
    if (isOnlyMiniCartCount === false) {
      dispatch({
        type: UPDATE_CART_CONTENT,
      });
    }
  }, [isOnlyMiniCartCount]);

  const cartPageRootElement = isClient
    ? document.getElementById("react-cart-page")
    : null;

  const productPageWarningsWrapper = isReactApp
    ? document.getElementById("react-warning-wrapper")
    : null;

  useEffect(() => {
    if (!isMiniCart) {
      dispatch({
        type: ENABLE_CART_API_REQUEST,
      });
    }
  }, [isMiniCart]);

  useSetCartCountEventListener(dispatch);

  const onCloseServiceProducts = () => {
    if (crossSalesProducts.length > 0) {
      setIsCrossSalesOpen(true);
    }

    setIsServicesOpen(false);
  };

  return (
    <>
      {isMiniCart === true ? (
        <MiniCartContent isCartPage={false} />
      ) : (
        !hideCartContent && (
          <>
            <MiniCartContent isCartPage={true} />
            {cartPageRootElement !== null &&
              createPortal(
                <>
                  {state.isLoading === true ? (
                    <XXLLoader />
                  ) : (
                    <CartContent
                      isTeamAdmin={isTeamAdminBoolean}
                      isTeamsalesAdmin={isTeamsalesAdmin === "true"}
                    />
                  )}
                </>,
                cartPageRootElement
              )}
          </>
        )
      )}
      {serviceResponse !== null &&
        cartEntryItemId !== undefined &&
        servicesElement !== null &&
        createPortal(
          <ServiceProducts
            cartEntryItemId={cartEntryItemId}
            category={category}
            productSize={productSize}
            serviceParentKey={parentKey}
            services={serviceResponse}
            onClose={onCloseServiceProducts}
            isOpen={isServicesOpen}
          />,
          servicesElement
        )}
      {productPageWarningsWrapper !== null &&
        createPortal(<CollectWarningOnPage />, productPageWarningsWrapper)}
      {crossSalesElement !== null &&
        crossSalesProducts.length > 0 &&
        productData !== null &&
        isCrossSalesOpen &&
        createPortal(
          <CrossSales
            clickAndCollectWarning={clickAndCollectWarning}
            crossSalesProducts={crossSalesProducts}
            onClickClose={() => setIsCrossSalesOpen(false)}
            productData={productData}
          />,
          crossSalesElement
        )}
      {!isMiniCart && (
        <>
          <LoginModalWrapper
            isOpen={hideCartContent}
            onClose={() => setHideCartContent(false)}
          />

          <SignupModalWrapper />
        </>
      )}
    </>
  );
};

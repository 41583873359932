import type { EcomSiteUid } from "@/react-app/global";
import { Configuration, SolrApi } from "@xxl/solr-api";
import {
  getElevateApiConfiguration,
  getSolrApiConfiguration,
} from "@/utils/environment-variables";
import {
  ElevateApi,
  PriceId,
  AllCustomAttributes,
} from "@xxl/product-search-api";
import type {
  LandingPageQuery,
  LandingPageRequestSettings,
  LandingPageResultData,
} from "@xxl/product-search-api";
import { convertSiteUidToHost } from "@/react-utils/xxl-shared-data";
import type { AxiosResponse } from "axios";
import type { ENGINE } from "@/react-utils/xxl-category";

interface ProductDiscountCountStrategy {
  accept(engine?: ENGINE): boolean;
  handle(categoryCode: string, ecomSite: EcomSiteUid): Promise<number>;
}

export class ElevateProductDiscountCountStrategy
  implements ProductDiscountCountStrategy
{
  accept(engine?: ENGINE): boolean {
    return engine === "ELEVATE";
  }

  async handle(categoryCode: string, ecomSite: EcomSiteUid): Promise<number> {
    const elevateApi = ElevateApi.getInstance(getElevateApiConfiguration(true));
    const query: LandingPageQuery = {
      sessionKey: "1",
      customerKey: "1",
      site: convertSiteUidToHost(ecomSite),
      touchpoint: "DESKTOP",
      priceId: PriceId.ANONYMOUS,
      presentCustom: AllCustomAttributes,
      pageReference: `/dynamic/c/${categoryCode}`,
      limit: 1,
    };

    const initialData: LandingPageRequestSettings = {
      primaryList: {
        include: true,
        productRules: `rule incl custom.categoryIds { "${categoryCode}" } excl custom.isOnCampaign { "false"}  excl custom.isOnPromotion { "false"}`,
      },
    };
    const result: AxiosResponse<LandingPageResultData> =
      await elevateApi.storefront.landingPagePOST(query, initialData);
    return result.data.primaryList.totalHits;
  }
}

export class SolrProductDiscountCountStrategy
  implements ProductDiscountCountStrategy
{
  private solrApi = new SolrApi(new Configuration(getSolrApiConfiguration()));
  accept(engine?: ENGINE): boolean {
    return engine === "SOLR" || engine === undefined;
  }

  async handle(categoryCode: string, ecomSite: EcomSiteUid): Promise<number> {
    const {
      data: {
        facet_counts: {
          facet_fields: { category_string_mv },
        },
      },
    } = await this.solrApi.getCategoriesPromotionProductsCount(ecomSite);
    const facetIndex = category_string_mv.indexOf(categoryCode);
    return facetIndex === -1
      ? 0
      : parseInt(category_string_mv[facetIndex + 1] ?? "0");
  }
}

import type { StoreWithId } from "@/react-components/Sort/AvailabilitySelector/DialogStoreSelect";
import type {
  CampaignHubData,
  GuideContentData,
  GuideHubContentData,
  HomePage20,
  ServicePromotion,
} from "@xxl/content-api";
import type { PaymentWidgetQuery } from "react-app/src/generated/graphql-code-generator";
import type { NextJsTranslations } from "react-app/src/utils/xxl-translate";
import type { PageData } from "../common-page-data/common-page-data";
import type { LayoutProps } from "../layout/with-layout-page-props";
import getXxlCache from "./cache";
import type { BrandsArrayProps } from "react-app/src/components/BrandIndex/BrandIndex";
import { log } from "@xxl/logging-utils";

export type PaymentWidgetData = Pick<
  PaymentWidgetQuery,
  "paymentWidget"
>["paymentWidget"];

type CacheName =
  | "BRAND_INDEX_PAGE_DATA"
  | "CAMPAIGN_HUB"
  | "GUIDES"
  | "GUIDE_HUB"
  | "HOMEPAGE"
  | "LAYOUT_PROPS"
  | "NEXT_JS_TRANSLATIONS"
  | "PAGE_DATA"
  | "PAYMENT_WIDGET"
  | "PRODUCT_SIZES_STOCK_STATUS"
  | "SERVICE_PROMOTIONS"
  | "STORE_DATA";
const FIVE_MINUTES_IN_MS = 300000;
const ONE_HOUR_IN_MS = 3600000;
const DEFAULT_CACHE_TIME = FIVE_MINUTES_IN_MS;
const NEXT_JS_TRANSLATIONS_CACHE_TIME = ONE_HOUR_IN_MS;

const getCachedValue = async <T>(key: CacheName): Promise<T | null> => {
  try {
    const result = await getXxlCache().then((cache) =>
      cache?.store.get<T>(key)
    );
    const isCacheHit = result !== undefined;
    log.debug(`[getCachedValue] ${key}: ${isCacheHit ? "hit" : "miss"}`);

    if (isCacheHit) {
      return result as T;
    }

    return null;
  } catch (error) {
    log.error({
      error,
      message: `[getCachedValue] Error while getting cache item: ${key}.`,
    });
    return null;
  }
};

const setCachedValue = async (key: CacheName, value: unknown, ttl: number) => {
  try {
    await getXxlCache().then((cache) => cache?.store.set(key, value, ttl));
  } catch (error) {
    log.error({
      error,
      message: `[getCachedValue] Error while setting cache item: ${key}.`,
    });
  }
};

// Campaign Hub
export const setCachedCampaignHub = (value: CampaignHubData[]) =>
  setCachedValue("CAMPAIGN_HUB", value, DEFAULT_CACHE_TIME);
export const getCachedCampaignHub = () =>
  getCachedValue<CampaignHubData[]>("CAMPAIGN_HUB");

// Guides
export const setCachedGuides = (value: GuideContentData[]) =>
  setCachedValue("GUIDES", value, DEFAULT_CACHE_TIME);
export const getCachedGuides = () =>
  getCachedValue<GuideContentData[]>("GUIDES");

// Guide Hub
export const setCachedGuideHub = (value: GuideHubContentData) =>
  setCachedValue("GUIDE_HUB", value, DEFAULT_CACHE_TIME);
export const getCachedGuideHub = () =>
  getCachedValue<GuideHubContentData>("GUIDE_HUB");

// Homepage
export const getCachedHomepage = () => getCachedValue<HomePage20>("HOMEPAGE");
export const setCachedHomepage = (value: HomePage20) =>
  setCachedValue("HOMEPAGE", value, DEFAULT_CACHE_TIME);

// Layout
export const setCachedLayoutProps = (value: LayoutProps) =>
  setCachedValue("LAYOUT_PROPS", value, DEFAULT_CACHE_TIME);
export const getCachedLayoutProps = () =>
  getCachedValue<LayoutProps>("LAYOUT_PROPS");

// Translations
export const setCachedNextJsTranslations = (value: NextJsTranslations) =>
  setCachedValue(
    "NEXT_JS_TRANSLATIONS",
    value,
    NEXT_JS_TRANSLATIONS_CACHE_TIME
  );
export const getCachedNextJsTranslations = () =>
  getCachedValue<NextJsTranslations>("NEXT_JS_TRANSLATIONS");

// Page
export const setCachedPageData = (value: PageData) =>
  setCachedValue("PAGE_DATA", value, DEFAULT_CACHE_TIME);
export const getCachedPageData = () => getCachedValue<PageData>("PAGE_DATA");

// Payment widget
export const setCachedPaymentWidgetValue = (value: PaymentWidgetData) => {
  const currentDate = Date.now();
  const CACHE_EXPIRES_TIME = value.expiresAt - currentDate - DEFAULT_CACHE_TIME;
  return setCachedValue("PAYMENT_WIDGET", value, CACHE_EXPIRES_TIME);
};
export const getCachedPaymentWidgetValue = () =>
  getCachedValue<PaymentWidgetData>("PAYMENT_WIDGET");

// Brandindex page
export const setCachedBrandIndexPageData = (value: BrandsArrayProps) =>
  setCachedValue("BRAND_INDEX_PAGE_DATA", value, DEFAULT_CACHE_TIME);
export const getCachedBrandIndexPageData = () =>
  getCachedValue<BrandsArrayProps>("BRAND_INDEX_PAGE_DATA");

// Service Promotions
export const getCachedServicePromotions = () =>
  getCachedValue<ServicePromotion[]>("SERVICE_PROMOTIONS");
export const setCachedServicePromotions = (value: ServicePromotion[]) =>
  setCachedValue("SERVICE_PROMOTIONS", value, DEFAULT_CACHE_TIME);

// Stores
export const getCachedStoreData = () =>
  getCachedValue<Required<StoreWithId>[]>("STORE_DATA");
export const setCachedStoreData = (value: Required<StoreWithId>[]) =>
  setCachedValue("STORE_DATA", value, DEFAULT_CACHE_TIME);

export const KeyvCacheNamespace = {
  CONTENT_PRODUCT_LISTING_SMALL_BANNERS: "content-product-listing-banners",
  LONG_TAIL_DATA: "long-tail-data",
  CONTENT_PRODUCT_LISTING_SMALL_BANNERS_AS_MAP:
    "content-product-listing-banners-as-map",
};

export const KeyvDefaultTtl = {
  ttl: 15 * 60 * 1000, // 15 minutes
  staleTtl: 3 * 60 * 1000, // 3 minutes
};

import { default as axios } from "axios";
import { log } from "@xxl/logging-utils";

/**
 * Get a new auth token if expired
 * @returns true if user is logged in false if not
 */
export const refreshAuthCookie = async (): Promise<boolean> => {
  try {
    const { status } = await axios.request({
      method: "GET",
      url: "/api/user/token/refresh",
      validateStatus: () => true,
    });
    if (200 === status) {
      return true;
    } else if (204 === status) {
      return false;
    } else if (401 === status) {
      // access and refresh tokens are expired, make sure cartId cookie is deleted
      await axios.request({
        method: "GET",
        url: "/logout",
        validateStatus: () => true,
      });
      return false;
    } else {
      const errMsg = `Failed to refreshAuthCookie, got status: ${status}`;
      log.error(errMsg);
      throw new Error(errMsg);
    }
  } catch (err) {
    log.error("Failed to refreshAuthCookie", err);
    throw err;
  }
};

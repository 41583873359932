import axios, { type AxiosResponse } from "axios";

type HazardSymbols = string[];

type Documents = {
  sds_pdf?: string;
  sps_pdf?: string;
};

type ExternalDocuments = {
  links?: string[];
  files?: string[];
};

export type EcoProduct = {
  product_name?: string;
  supplier?: string;
  product_group?: string;
  area_of_use?: string;
  hazard_statements?: string;
  synonyms?: string;
  classification?: string;
  article_no?: string;
  date?: string;
  language?: number;
  description?: string;
  hazard_symbols?: HazardSymbols;
  documents?: Documents;
  external_documents?: ExternalDocuments;
};

type EcoResponseProps = {
  data?: EcoProduct[];
  columns?: string[];
  limit?: number;
  noOfResults?: number;
};

export const fetchEcoOnlineData =
  ({ baseUrl, apiKey }: { baseUrl: string; apiKey: string }) =>
  async (
    productId: string,
    siteHost: string
  ): Promise<AxiosResponse<EcoResponseProps>> => {
    const queryResponse = await axios({
      url: `${baseUrl}?limit=25&
    page=0&
    orderBy=product_name&
    direction=asc&
    selectedColumns=
      product_name,
      supplier,
      internal_no,
      product_group,
      language,
      date,
      area_of_use,
      hazard_statements,
      synonyms,
      classification,
      article_no,
      hazard_symbols,
      description,
      sds,
      sps,
      external_documents
    &
    advancedSearch=1&
    languages[]=2&
    markets[]=2&
    article_no=${productId}&
    allowed_domains[]=${siteHost}`
        .split("\n")
        .map((s) => s.trim())
        .filter(Boolean)
        .join(""),
      headers: {
        "X-SEARCH-CONFIGURATION-API-KEY": apiKey,
        Accept: "application/json",
      },
    });

    return queryResponse;
  };

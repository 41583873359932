import React from "react";
import { useRelinkValue } from "react-relink";
import { useSessionSource } from "../../../contexts/Session";
import { useSharedData } from "../../../contexts/SharedData";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import type { ProductType } from "../../../utils/data-types";
import { getFrontendPriceDisplayData } from "../../../utils/PriceDisplay/price-display";
import type { GetFrontendPriceDisplayDataProps } from "../../../utils/PriceDisplay/types";
import type { PriceData } from "../../../utils/Search/search-helper";
import {
  getPriceDisplaysForPackages,
  isSoldInPackages,
} from "../product-helper";
import { PriceDescriptionWidget } from "../Product.styled";
import { PriceWithLabelsV1 } from "./PriceWithLabelsV1";
import { PriceWithLabelsV2 } from "./PriceWithLabelsV2";
import { log } from "@xxl/logging-utils";

type PriceWithLabelsProps =
  | {
      productType: ProductType;
      version: 1;
      priceDisplay?: GetFrontendPriceDisplayDataProps["priceDisplay"];
      productCode?: string;
      showPackagePrice?: boolean;
      selectedColumnsNumber: number;
      t: Translate;
      units?: {
        code?: string;
        visible?: boolean;
      }[];
      widget?: JSX.Element;
    }
  | {
      priceData: {
        selling: PriceData;
        alternate?: PriceData;
        disclaimer?: string;
      };
      selectedColumnsNumber: number;
      version: 2;
    };

const PriceWithLabels: React.FunctionComponent<
  PriceWithLabelsProps & { isCompact?: boolean }
> = (props) => {
  const {
    siteUid,
    featureToggles: { toggle_products_as_package_quantity },
  } = useSharedData().data;
  const isLoggedIn = useRelinkValue(useSessionSource);
  const showDisclaimer = siteUid === "xxl-fi";

  if (props.version === 2) {
    const { selectedColumnsNumber, priceData } = props;
    return (
      <PriceWithLabelsV2
        selectedColumnsNumber={selectedColumnsNumber}
        priceData={priceData}
        showDisclaimer={showDisclaimer}
      />
    );
  }

  const {
    productCode,
    productType,
    priceDisplay,
    showPackagePrice = false,
    selectedColumnsNumber,
    t,
    units,
    isCompact = false,
  } = props;
  const isMultiPackProduct = productType === "MULTIPACK";
  const showPackagePriceValues = showPackagePrice || isMultiPackProduct;
  const isPackageSell = isSoldInPackages(units) || isMultiPackProduct;
  const priceDisplayWithPackages =
    isPackageSell &&
    toggle_products_as_package_quantity &&
    showPackagePriceValues
      ? getPriceDisplaysForPackages(priceDisplay, units)
      : priceDisplay;

  if (priceDisplayWithPackages === undefined) {
    log.error(
      `Price display is undefined for product with code ${productCode !== undefined ? productCode : "undefined"}`
    );
    return null;
  }

  const frontendPriceDisplay = getFrontendPriceDisplayData({
    isLoggedIn,
    priceDisplay: priceDisplayWithPackages,
    siteUid,
    t,
  });

  return (
    <PriceWithLabelsV1
      isMultiPackProduct={productType === "MULTIPACK"}
      priceDisplay={{
        hasLowerProductConfiguratorPriceThanSalesPrice:
          frontendPriceDisplay.hasLowerProductConfiguratorPriceThanSalesPrice,
        isDiscountedMainPrice: frontendPriceDisplay.isDiscountedMainPrice,
        isInvertedPrice: frontendPriceDisplay.isInvertedPrice,
        salesPrice: frontendPriceDisplay.salesPrice,
        otherPrice: frontendPriceDisplay.otherPrice,
        otherPriceDisclaimer: frontendPriceDisplay.otherPriceDisclaimer,
        salesPriceDisclaimer: frontendPriceDisplay.salesPriceDisclaimer,
        invertedPrice: priceDisplay?.invertedPrice,
        invertedPriceFormatted: priceDisplay?.invertedPriceFormatted,
        type: priceDisplay?.type,
      }}
      selectedColumnsNumber={selectedColumnsNumber}
      productCode={productCode}
      widget={
        isPackageSell &&
        toggle_products_as_package_quantity &&
        showPackagePriceValues ? (
          <PriceDescriptionWidget>
            {t("product.unit.per.box.description")}
          </PriceDescriptionWidget>
        ) : undefined
      }
      isCompact={isCompact}
    />
  );
};

export { PriceWithLabels };
export type { PriceWithLabelsProps };

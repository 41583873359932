import { isNotNull, isNotNullOrUndefined } from "@xxl/common-utils";
import { type EcoProduct } from "../../../../utils/pdp-page-helper/fetchEcoOnlineData";
import {
  HazardousDataDescription,
  HazardousDataList,
  HazardousDataListItem,
  Heading,
  Link,
  ListItem,
  SymbolsImage,
  SymbolsItem,
  SymbolsList,
} from "./HazardousData.styled";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";

export const HazardousDataItem = ({
  product_name,
  documents,
  hazard_statements,
  hazard_symbols,
  description,
}: EcoProduct) => {
  const { t } = useTranslations();

  const hazardStatements: string[] | null =
    isNotNullOrUndefined(hazard_statements) && hazard_statements.length > 0
      ? hazard_statements.split("\n")
      : null;

  return (
    <ListItem>
      {isNotNullOrUndefined(product_name) && (
        <Heading>
          {t("product.description.hazardous.data.prefix")}&nbsp;
          {product_name}
        </Heading>
      )}
      {isNotNullOrUndefined(documents) &&
        isNotNullOrUndefined(documents.sds_pdf) && (
          <Link
            href={documents.sds_pdf}
            download={t("product.description.hazardous.download.link.name")}
            target="_blank"
          >
            {t("product.description.hazardous.data.link")}
          </Link>
        )}
      {isNotNull(hazardStatements) && (
        <HazardousDataList>
          {hazardStatements.map((item, index) => (
            <HazardousDataListItem key={index}>{item}</HazardousDataListItem>
          ))}
        </HazardousDataList>
      )}
      {isNotNullOrUndefined(description) && description.length > 0 && (
        <HazardousDataDescription>{description}</HazardousDataDescription>
      )}
      {isNotNullOrUndefined(hazard_symbols) && hazard_symbols.length > 0 && (
        <SymbolsList>
          {hazard_symbols.map((item, index) => (
            <SymbolsItem key={index}>
              <SymbolsImage
                src={item}
                alt={t("product.description.hazardous.icon.alt")}
              />
            </SymbolsItem>
          ))}
        </SymbolsList>
      )}
    </ListItem>
  );
};

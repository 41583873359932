/* eslint-disable import/no-cycle */
import noop from "lodash/noop";
import type { SharedData } from "../../global";
import type { Translations } from "../../translations";
import { lockScreen } from "../LockScreen/lock-screen";
import { toggleScrollbar } from "../ToggleScrollbar";
import { uuidv4 } from "../xxl-uuid";
import { log } from "@xxl/logging-utils";

const getWindow = (): Window | null =>
  typeof window === "undefined" ? null : window;

/**
 * This function returns a mirrored version of window._sharedData, for server side use.
 * Add/update properties here when used by components that are used in the react-app and next-js-app.
 */
let hasLoggedError = false;
export const getSsrSharedData = (): SharedData => {
  if (
    typeof window !== "undefined" &&
    "next" in window &&
    window.location.host.includes("localhost") &&
    !hasLoggedError
  ) {
    log.error(
      "Please try to use value from React context instead of accessing it via window object as this may not be correct value in NextJS land."
    );
    hasLoggedError = true;
  }
  return {
    baseApiUrl: "",
    cart: {
      totalPrice: 0,
      lines: [],
      customer: {},
    },
    captcha: {
      siteKey: process.env.CAPTCHA_SITEKEY ?? "",
    },
    categoryFacet: "",
    configuration: {
      searchApi: {
        basePath: "https://api.dev.xxlsports.com/search-api-v3",
        baseOptions: {
          timeout: "10",
        },
      },
      frontendApi: {
        basePath: "https://api.dev.xxlsports.com/search-api-v3",
      },
      contentApi: {},
      customersApi: {},
      deliveryStreamApi: {},
      elevateApi: {
        baseURL: "",
      },
      elevateClusterApi: {
        baseURL: "",
      },
      shipmentsApi: {},
      paymentsApi: {},
      pimApi: {},
      productConfiguratorApi: {},
      recommendationsApi: {
        basePath: "https://api.dev.xxlsports.com/recommendations-api-v4",
      },
      amplifyConfig: {
        aws_project_region: "",
        aws_appsync_graphqlEndpoint: "",
        aws_appsync_region: "",
        aws_appsync_authenticationType: "",
        aws_appsync_apiKey: "",
        Auth: {
          region: "",
          userPoolId: "",
          userPoolWebClientId: "",
          mandatorySignIn: false,
          authenticationFlowType: "",
        },
      },
      campaignHubPath: "",
      guidesInterfix: "",
    },
    cookieVersion: "v4",
    cookieCultureLanguage: "",
    eventStreamEndpoint: "",
    featureToggles: {
      fitstation: false,
      toggle_cacheable_campaign_search_query: false,
      toggle_campaign_page: false,
      toggle_click_and_collect: false,
      toggle_click_and_collect_for_goods_only_in_store: false,
      toggle_click_and_collect_KCO: false,
      toggle_click_and_collect_quantity: false,
      toggle_cross_sales: false,
      toggle_delivery_widget: false,
      toggle_elevate_categories: false,
      toggle_elevate_search: false,
      toggle_elevate_pdp: false,
      checkout_psp_walley: false,
      giftcards_psp_walley: false,
      return_replacement_psp_walley: false,
      toggle_exit_austria: false,
      toggle_favorites: false,
      toggle_force_solr_as_provider_on_pages: "",
      toggle_free_delivery_widget: false,
      toggle_ga_ssr: false,
      toggle_klarna_osm_configuration_externalized: false,
      toggle_kindly_chatbot: false,
      toggle_left_column_page_filters: false,
      toggle_log_rocket: false,
      toggle_lowest_price: false,
      toggle_new_guides: false,
      toggle_paperless_returns: false,
      toggle_personal_shoewall: false,
      toggle_prisjakt: false,
      toggle_ratings_and_reviews: false,
      toggle_react_cat_search: false,
      toggle_return_fee: false,
      toggle_returns_digital_only: false,
      toggle_reward_page: false,
      toggle_rewards: false,
      toggle_social_security_number: false,
      toggle_sticky_filter: false,
      toggle_triggerbee: false,
      toggle_vipps_signup: false,
      toggle_otp_login: false,
      toggle_products_as_package_quantity: false,
      toggle_plp_one_card_content_component: false,
      toggle_christmas_mode: false,
      toggle_seo_product_archival_page: false,
      toggle_complete_profile_points: false,
      toggle_rate_and_review_section: false,
      toggle_plp_buying_guides: false,
      toggle_quick_shop: false,
      toggle_videoly: false,
      toggle_fitstation: false,
      toggle_elevate_cluster_search: false,
      toggle_elevate_cluster_landing_page: false,
    },
    giosg: {
      giosgEnabled: false,
      giosgId: "1",
    },
    gtmId: "",
    gtmData: {},
    isTeamsales: false,
    kindlyChatbotKey: "",
    kindlyChatbotLanguage: "",
    klarnaLanguage: "",
    language: "",
    logRocketApiId: "",
    numberOfProductsPerPage: 1,
    megaMenuLevel1Categories: [],
    pageType: "",
    order: {
      lines: [],
    },
    products: [],
    query: "",
    requestMapping: {
      account: "",
      brands: "",
      campaignHubPage: "",
      clubAssortment: "",
      customerService: "",
      faq: "",
      faqGiftCard: "",
      giftCard: "",
      guides: "",
      login: "",
      myAccount: {
        favorites: "",
        fitstation: "",
        orders: "",
        rewards: "",
        settings: ",",
        rateAndReview: "",
      },
      newsletter: "",
      outlet: "",
      qrReturn: "",
      reward: "",
      search: "/search",
      shared: "",
      store: "",
      storeFinder: "",
      workshop: "",
    },
    rewardSignupPageUrl: "",
    serverGtmScriptUrl: "",
    serviceDescriptions: null,
    signUpImageUrl: null,
    siteUid: "xxl-se",
    siteCurrency: "",
    siteSubDomain: "",
    siteDomain: "",
    siteCountry: "",
    siteDefaultLanguage: "",
    skipTrackConfirmation: false,
    symplifyId: "",
    translatedPriceDisplayTypes: [],
    translations: {} as Translations,
    videolyId: "",
    volumentalId: "",
    gitHash: "",
  };
};

/**
 * Use this function to safely access application properties on the window object.
 * This abstraction makes it possible to access our own properties and functions on window without littering the code with
 * checks for typeof window !== "undefined", which is required since the window object does not exist server side.
 * When accessing browser native properties on window, use window directly with the check for undefined.
 * When the app is fully served by Next, windowAccess will have been phased out.
 */
const windowAccess = () => {
  return {
    _klarnaCheckout: getWindow()?._klarnaCheckout,
    _sharedData: getWindow()?._sharedData ?? getSsrSharedData(),
    BundleGlobal: getWindow()?.BundleGlobal,
    Checkout: getWindow()?.Checkout ?? {
      initialize: () => log.error("Checkout.initialize is not implemented"),
    },
    Common: getWindow()?.Common ?? {
      uuidv4,
    },
    Cookie: getWindow()?.Cookie ?? {
      getCookie: () => () => log.error("getCookie not implemented"),
      getCartDetailsCookie: () =>
        log.error("getCartDetailsCookie not implemented"),
    },
    XxlHelpers: {
      ...getWindow()?.XxlHelpers,
      setStorageItem: () => log.error("setStorageItem not implemented"),
    },
    eventstream: getWindow()?.eventstream,
    megaMenu: getWindow()?.megaMenu,
    mtr_custom: getWindow()?.mtr_custom,
    Gtm: getWindow()?.Gtm,
    ToggleScrollbar: getWindow()?.ToggleScrollbar ?? toggleScrollbar,
    location: getWindow()?.location,
    LockScreen: getWindow()?.LockScreen ?? lockScreen,
    HtmlUtils: getWindow()?.HtmlUtils,
    xxlServiceProducts: getWindow()?.xxlServiceProducts,
    Cart: getWindow()?.Cart,
    Login: getWindow()?.Login ?? {
      initialize: noop,
    },
    KlarnaPlacementWidget: getWindow()?.KlarnaPlacementWidget,
    AnimateElement: getWindow()?.AnimateElement,
    KeyboardUtil: getWindow()?.KeyboardUtil,
    fbq: getWindow()?.fbq,
    walley: getWindow()?.walley,
  };
};

export { windowAccess };

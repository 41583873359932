import type { FunctionComponent } from "react";
import React from "react";
import {
  FooterWrapper,
  InnerFooter,
  HeadingWrapper,
  Heading,
  ToTop,
  Service,
  About,
  SocialListColumn,
  SiteLogos,
  SiteLogosInnerWrapper,
  ThirdPartyLogos,
  ThirdPartyLogo,
} from "./SiteFooter.styled";
import { Triangle } from "@xxl/icons";
import type {
  FooterImage,
  FooterLinkList,
  FooterLogo,
  SocialMediaUrls,
} from "../../utils/apis/content-footer-api";
import { LinkList } from "./LinkList";
import { SocialMediaList } from "./SocialMediaList/SocialMediaList";
import { Logo } from "./Logos";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import HoneyPot from "../honeypot/honeypot";
import { showStickyHeader } from "react-app/src/utils/xxl-toggle-sticky-header";

type FooterInData = {
  linkListOne: FooterLinkList | null;
  linkListTwo: FooterLinkList | null;
  logo: FooterLogo | null;
  socialMediaUrls: SocialMediaUrls | null;
  thirdPartyLogos: FooterImage[] | null;
};

const SiteFooter: FunctionComponent<FooterInData> = ({
  linkListOne,
  linkListTwo,
  logo,
  socialMediaUrls,
  thirdPartyLogos,
}) => {
  const { t } = useTranslations();

  return (
    <FooterWrapper>
      <InnerFooter>
        <HeadingWrapper>
          <Heading src="/static/svg/footer-slogan.svg" alt="XXL logo" />
        </HeadingWrapper>
        <ToTop href="#top" onClick={showStickyHeader}>
          <Triangle />
          {t("footer.top.button")}
        </ToTop>

        <Service>
          {linkListOne !== null && <LinkList linkList={linkListOne} />}
        </Service>
        <About>
          {linkListTwo !== null && <LinkList linkList={linkListTwo} />}
        </About>
        <SocialListColumn>
          {socialMediaUrls !== null && (
            <SocialMediaList urls={socialMediaUrls} />
          )}
        </SocialListColumn>

        <SiteLogos>
          <SiteLogosInnerWrapper>
            {logo !== null && <Logo url={logo.url} link={logo.link} />}
            <ThirdPartyLogos>
              <ThirdPartyLogo>
                {thirdPartyLogos?.map(({ link, url }, index) => (
                  <Logo key={index} {...{ url, link }} />
                ))}
              </ThirdPartyLogo>
            </ThirdPartyLogos>
          </SiteLogosInnerWrapper>
          <p>{t("footer.xxl.copyright")}</p>
        </SiteLogos>
      </InnerFooter>
      <HoneyPot />
      <div id="js-react-cart-cross-sales"></div>
      <div id="js-react-cart-services"></div>
    </FooterWrapper>
  );
};

export default SiteFooter;

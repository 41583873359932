import React from "react";
import { useRelinkValue } from "react-relink";
import { useSessionSource } from "../../../contexts/Session";
import { useSharedData } from "../../../contexts/SharedData";
import type { PriceDisplayVariant } from "../../../utils/data-types";
import {
  hasUndeductedRewardDiscount,
  showSalesPriceDisclaimer,
} from "../../../utils/PriceDisplay/price-display";
import { OtherPrice } from "../../PriceDisplays/PriceDisplays.styled";
import {
  CurrentPrice,
  OriginalPrice,
  PriceWrapper,
  SalesPriceDisclaimer,
} from "../Product.styled";

type ProductPriceInfoProps = {
  selectedColumnsNumber?: number;
  priceDisplay: {
    hasLowerProductConfiguratorPriceThanSalesPrice: boolean;
    isDiscountedMainPrice: boolean;
    isInvertedPrice: boolean;
    salesPrice: string;
    otherPrice?: string;
    otherPriceDisclaimer?: string;
    salesPriceDisclaimer?: string;
    invertedPrice?: number;
    invertedPriceFormatted?: string;
    type?: PriceDisplayVariant;
  };
  isMultiPackProduct?: boolean;
  productCode?: string;
  widget?: JSX.Element;
  isCompact: boolean;
};

export const PriceWithLabelsV1: React.FunctionComponent<
  ProductPriceInfoProps
> = ({
  selectedColumnsNumber,
  priceDisplay,
  widget,
  isMultiPackProduct,
  isCompact = false,
}) => {
  const isLoggedIn = useRelinkValue(useSessionSource);
  const { siteUid } = useSharedData().data;
  const {
    hasLowerProductConfiguratorPriceThanSalesPrice,
    isDiscountedMainPrice,
    isInvertedPrice,
    otherPrice,
    otherPriceDisclaimer,
    salesPrice,
    salesPriceDisclaimer,
  } = priceDisplay;

  return (
    <PriceWrapper data-testid="new-product-card-price-display">
      <CurrentPrice
        data-testid="current-price"
        columnAmount={selectedColumnsNumber}
        isDiscountPrice={
          isDiscountedMainPrice === true ||
          (isInvertedPrice === true && isLoggedIn)
        }
      >
        {salesPrice}
        {widget !== undefined ? widget : null}
      </CurrentPrice>

      {!isCompact || otherPrice !== undefined ? (
        <OriginalPrice columnAmount={selectedColumnsNumber}>
          {otherPrice !== undefined ? (
            <>
              {otherPriceDisclaimer !== undefined && (
                <span>{otherPriceDisclaimer}</span>
              )}
              <OtherPrice
                hasLowerProductConfiguratorPriceThanSalesPrice={
                  hasLowerProductConfiguratorPriceThanSalesPrice
                }
                hasUndeductedRewardDiscount={hasUndeductedRewardDiscount({
                  invertedPrice: priceDisplay.invertedPrice,
                  invertedPriceFormatted: priceDisplay.invertedPriceFormatted,
                  variant: priceDisplay.type,
                  isMultiPackProduct: isMultiPackProduct ?? false,
                })}
                isInvertedPrice={isInvertedPrice}
              >
                {otherPrice}
              </OtherPrice>
            </>
          ) : null}
        </OriginalPrice>
      ) : null}
      {showSalesPriceDisclaimer(siteUid) ? (
        <SalesPriceDisclaimer title={salesPriceDisclaimer}>
          {salesPriceDisclaimer}
        </SalesPriceDisclaimer>
      ) : null}
    </PriceWrapper>
  );
};

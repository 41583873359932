import React, { useEffect, useState } from "react";
import { ProductFormContainer } from "../ProductDetailsPage/ProductForm/ProductFormContainer";
import { useCallback } from "rely-use-callback";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useIsSizeSelectDrawerOpen } from "../ProductDetailsPage/ProductDetailsPage.helper";
import {
  getMinimalStockLevelForPackage,
  transformSizeIdToStyle,
} from "react-app/src/components/Product/product-helper";
import {
  PdpContext,
  usePdpReducer,
} from "react-app/src/components/ProductDetailsPage/ProductDetailsPage.state";
import { getStoresWithCollectableProduct } from "react-app/src/components/ClickAndCollect/api/api";
import { AddToCartWarning } from "src/components/ProductDetailsPage/AddToCartWarning";
import { hasNoValue, isNotEmpty } from "@xxl/common-utils";
import type { OnSelectSizeProps } from "src/components/ProductDetailsPage/ProductForm/SizeOptions";
import type { FrontendProductData } from "react-app/src/hooks/useProductData/useProductData.types";
import { useAddToCart } from "react-app/src/hooks/useAddToCart/useAddToCart";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { XxlButton } from "react-app/src/components/Common/XxlButton/XxlButton";
import { useAddToCartError } from "../../hooks/useAddToCartError";
import { log } from "@xxl/logging-utils";

type Props = {
  productData: FrontendProductData;
  selectedProductCode: string;
  closeQuickShop: () => void;
  ticket?: string;
};

const ADD_TO_CART_TIMEOUT_MS = 1500;

const QuickShopAddToCart = ({
  closeQuickShop,
  productData,
  selectedProductCode,
  ticket,
}: Props) => {
  const { t } = useTranslations();
  const {
    name,
    brand,
    clickAndCollectEnabled = false,
    configurations,
    isPurchaseAllowed,
    onlyAvailableInStoreNoClickAndCollect = false,
    priceDisplay,
    productType,
    quantityToggleEnabled = false,
    salesMethod,
    sizeOptions,
    units,
    visibilityStatus,
    url,
  } = productData;
  const isDiscontinued = visibilityStatus?.discontinued ?? false;
  const isMultiPackProduct = productType === "MULTIPACK";
  const hasConfigurations =
    isNotEmpty(configurations) && configurations.length > 0;
  const isSizeCode =
    sizeOptions?.some(({ code }) => code === selectedProductCode) ?? false;
  const styleCode = isSizeCode
    ? transformSizeIdToStyle(selectedProductCode)
    : selectedProductCode;
  const { addToCart, addToCartState, unit } = useAddToCart({
    productData,
    selectedProductCode,
  });
  const [state, dispatch] = usePdpReducer();
  const [activeError, setActiveError] = useState(false);
  const {
    configuration: {
      amplifyConfig: { aws_appsync_graphqlEndpoint, aws_appsync_apiKey },
    },
    featureToggles: { toggle_click_and_collect_KCO },
  } = useSharedData().data;
  const {
    isSizeSelectDrawerOpen,
    setIsSizeSelectDrawerOpen,
    toggleIsSizeSelectDrawerOpen,
  } = useIsSizeSelectDrawerOpen();
  const { clearAddToCartError } = useAddToCartError();
  const [selectedOptionData, setSelectedOptionData] =
    useState<OnSelectSizeProps>();
  const [shouldAddToCartOnSizeSelect, setShouldAddToCartOnSizeSelect] =
    useState(false);
  const [isCnCDialogOpen, setIsCnCDialogOpen] = useState(false);
  const toggleIsCnCDialogOpen = () => setIsCnCDialogOpen(!isCnCDialogOpen);
  const selectedOption = sizeOptions?.find(
    ({ ean }) => ean === selectedOptionData?.ean
  );
  const isClickAndCollectEnabled =
    toggle_click_and_collect_KCO &&
    !isMultiPackProduct &&
    !hasConfigurations &&
    clickAndCollectEnabled &&
    isPurchaseAllowed &&
    !(visibilityStatus?.onlyAvailableOnline ?? true);

  useEffect(() => {
    if (selectedOptionData?.ean === undefined) {
      return;
    }

    void (async () => {
      try {
        dispatch({ type: "isFetchingStores", payload: true });

        const storesWithCollectableProduct =
          await getStoresWithCollectableProduct(
            selectedOptionData.ean,
            aws_appsync_graphqlEndpoint,
            aws_appsync_apiKey,
            false,
            (() => {
              if (isMultiPackProduct && units !== undefined) {
                return getMinimalStockLevelForPackage(units);
              }
              return 1;
            })()
          );

        dispatch({
          type: "updateStores",
          payload: storesWithCollectableProduct,
        });
      } catch (error) {
        dispatch({ type: "updateStores", payload: [] });
        log.error("Could not fetch store information.");
      } finally {
        dispatch({ type: "isFetchingStores", payload: false });
      }
    })();
  }, [
    isMultiPackProduct,
    selectedOptionData?.ean,
    dispatch,
    aws_appsync_graphqlEndpoint,
    aws_appsync_apiKey,
    units,
  ]);

  const onSelectSize = useCallback(
    (sizeData: OnSelectSizeProps) => {
      clearAddToCartError();
      setIsSizeSelectDrawerOpen(false);
      setSelectedOptionData(sizeData);
      if (shouldAddToCartOnSizeSelect) {
        const { ean } = sizeData;
        addToCart({
          ean,
        });
        setShouldAddToCartOnSizeSelect(false);
      }
    },
    [setIsSizeSelectDrawerOpen, shouldAddToCartOnSizeSelect, addToCart]
  );

  const addToCartFromBtn = (storeId?: string) => {
    const { ean, quantity } = selectedOptionData ?? {};
    const isClickAndCollect = storeId !== undefined;
    if (ean === undefined) {
      setShouldAddToCartOnSizeSelect(true);
      toggleIsSizeSelectDrawerOpen();
      return;
    }

    addToCart({
      ean,
      storeId,
      quantity,
      ticket,
    });

    setActiveError(true);

    setTimeout(() => {
      setActiveError(false);
      if (!isClickAndCollect) {
        closeQuickShop();
      }
    }, ADD_TO_CART_TIMEOUT_MS);
  };

  const navigeteToPdp = () => {
    if (isNotEmpty(url)) {
      location.href = url;
    }
  };

  if (hasNoValue(sizeOptions) || hasConfigurations) {
    return (
      <XxlButton variant="primary" onClick={navigeteToPdp}>
        {t("product.details.configure.and.buy")}
      </XxlButton>
    );
  }

  return (
    <PdpContext.Provider value={{ dispatch, state }}>
      <ProductFormContainer
        hasConfigurations={hasConfigurations}
        isDiscontinued={isDiscontinued}
        shouldShowArchivedForm={false}
        productName={name ?? ""}
        brandCode={brand?.code ?? ""}
        busyState={addToCartState}
        isClickAndCollectEnabled={isClickAndCollectEnabled}
        isDkMarketInProd={false}
        isPurchaseAllowed={isPurchaseAllowed}
        isSizeSelectDrawerOpen={isSizeSelectDrawerOpen}
        onAddToCart={(storeId) => addToCartFromBtn(storeId)}
        onlyAvailableInStoreNoClickAndCollect={
          onlyAvailableInStoreNoClickAndCollect
        }
        onSelectSize={onSelectSize}
        quantityToggleEnabled={quantityToggleEnabled}
        salesPrice={priceDisplay.salesPriceFormatted}
        selectedOption={selectedOption}
        sizeOptions={sizeOptions}
        toggleIsSizeSelectDrawerOpen={toggleIsSizeSelectDrawerOpen}
        styleCode={styleCode}
        unit={unit}
        salesMethod={salesMethod ?? ""}
        isCnCDialogOpen={isCnCDialogOpen}
        toggleIsCnCDialogOpen={toggleIsCnCDialogOpen}
        shouldShowfitstation={false}
      />
      {activeError && <AddToCartWarning />}
    </PdpContext.Provider>
  );
};

export { QuickShopAddToCart };

import styled from "@emotion/styled/macro";
import parse from "html-react-parser";
import { RibbonHeading } from "../RibbonHeading";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { resolutionSizes } from "react-app/src/config";
import type { StrictClassification, ProductLink } from "../types";
import { SECTION_NAMES } from "../constants";
import { SectionWithHeaderOffset } from "../../common/SectionWithHeaderOffset/SectionWithHeaderOffset.styled";
import { useClassifications } from "../../../hooks/useClassifications";
import { Article, Description, Ul } from "./ProductDescriptions.styled";
import type { ServicePromotion } from "@xxl/content-api";
import { isNotEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import { Text } from "react-app/src/components/Text/Text";
import { ProductLinkComponent } from "./ProductLinkComponent";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import type { EcoOnlineConfigData } from "../../Pdp/pdp.types";
import { useSharedData } from "react-app/src/contexts/SharedData";
import {
  getSiteHost,
  legacySiteUidToSiteUid,
} from "react-app/src/utils/xxl-shared-data";
import {
  type EcoProduct,
  fetchEcoOnlineData,
} from "../../../utils/pdp-page-helper/fetchEcoOnlineData";
import { HazardousData } from "../../Guides/components/HazardousData/HazardousData";
import { log } from "@xxl/logging-utils";

const ProductDescriptionGrid = styled.div`
  display: grid;
  grid-gap: ${xxlTheme.spaces.regular};
  row-gap: ${xxlTheme.spaces.large};
  @media (min-width: ${resolutionSizes.laptop}px) {
    grid-template-columns: 1fr 1fr;
    gap: ${xxlTheme.spaces.big};
  }
`;

type Props = {
  classifications: StrictClassification[];
  description: string | null;
  servicePromotions: ServicePromotion[];
  productLink: ProductLink | null;
  ecoOnlineConfigData?: EcoOnlineConfigData;
  productId?: string;
};
export const ProductDescriptions = ({
  classifications = [],
  description,
  servicePromotions,
  productLink,
  ecoOnlineConfigData,
  productId,
}: Props) => {
  const { t } = useTranslations();
  const { ref, inView } = useInView();

  const formattedClassifications = useClassifications(classifications, {
    limit: classifications.length,
    positiveOnly: false,
  });
  const [shouldCallEcoEndpoint, setShouldCallEcoEndpoint] = useState(true);
  const [ecoData, setEcoData] = useState<EcoProduct[]>();
  const { siteUid } = useSharedData().data;
  const siteHost = getSiteHost(legacySiteUidToSiteUid(siteUid));

  useEffect(() => {
    if (
      isNotNullOrUndefined(ecoOnlineConfigData) &&
      isNotNullOrUndefined(productId)
    ) {
      const { baseUrl, apiKey } = ecoOnlineConfigData;
      const _getEcoOnlineData = async (): Promise<void> => {
        try {
          const response = await fetchEcoOnlineData({ baseUrl, apiKey })(
            productId,
            siteHost
          );
          if (response.data.data !== undefined) {
            setEcoData(response.data.data);
          } else {
            setEcoData([]);
          }
        } catch (error) {
          log.error("Cannot get EcoOnline data", error);
        }
      };
      if (inView && ecoData === undefined && shouldCallEcoEndpoint) {
        setShouldCallEcoEndpoint(false);
        void _getEcoOnlineData();
      }
    } else {
      setShouldCallEcoEndpoint(false);
    }
  }, [
    inView,
    ecoData,
    shouldCallEcoEndpoint,
    productId,
    siteHost,
    ecoOnlineConfigData,
  ]);

  return (
    <ProductDescriptionGrid ref={ref}>
      {isNotEmpty(description) ? (
        <ErrorBoundary>
          <SectionWithHeaderOffset id={SECTION_NAMES.description}>
            <RibbonHeading>
              {t("product.details.description.tab")}
            </RibbonHeading>
            <Article>
              <ProductLinkComponent productLink={productLink} />
              <Description>{parse(description)}</Description>
              {ecoData !== undefined && ecoData.length > 0 && (
                <HazardousData ecoProducts={ecoData} />
              )}
            </Article>
          </SectionWithHeaderOffset>
        </ErrorBoundary>
      ) : null}
      {classifications.length === 0 ? null : (
        <ErrorBoundary>
          <SectionWithHeaderOffset id={SECTION_NAMES.specifications}>
            <RibbonHeading>
              {t("product.details.specification.tab")}
            </RibbonHeading>
            <Ul>
              {formattedClassifications.map(([name, value]) => (
                <li key={name}>
                  <Text typography="baseBold" as="span">
                    {name}:&nbsp;
                  </Text>
                  <Text as="span">{value}</Text>
                </li>
              ))}
            </Ul>
          </SectionWithHeaderOffset>
        </ErrorBoundary>
      )}
      {servicePromotions.length === 0 ? null : (
        <ErrorBoundary>
          <SectionWithHeaderOffset id={SECTION_NAMES.servicePromotions}>
            <RibbonHeading>
              {t("product.details.servicepromotion.tab")}
            </RibbonHeading>
            <>
              {servicePromotions.map(({ content }, index) => {
                if (content === undefined) {
                  return null;
                }
                return (
                  <Article key={index}>
                    <Text>{parse(content)}</Text>
                  </Article>
                );
              })}
            </>
          </SectionWithHeaderOffset>
        </ErrorBoundary>
      )}
    </ProductDescriptionGrid>
  );
};

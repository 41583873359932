import { createContext, useContext } from "react";
import type { DeliverystreamApi } from "@xxl/deliverystream-api";
import type { AuthApi, PricesApi } from "@xxl/frontend-api";
import type { PimApi } from "@xxl/pim-api";
import type {
  CalculateApi,
  SearchApi as ProductConfiguratorSearchApi,
} from "@xxl/product-configurator-api";
import type { ContentApi } from "@xxl/content-api";
import type { RecommendationsApi } from "@xxl/recommendations-api";
import type { CustomersApi } from "@xxl/customers-api";
import type { SearchApi } from "@xxl/search-api";
import type { nextFrontendApi } from "../../api/NextFrontendApi";
import type { ElevateApi } from "@xxl/product-search-api";

export type ApiClients = {
  authApi: AuthApi;
  calculateApi: CalculateApi;
  contentApi: ContentApi;
  customersApi: CustomersApi;
  deliveryStreamApi: DeliverystreamApi;
  elevateApi: ElevateApi<unknown>;
  elevateClusterApi: ElevateApi<unknown>;
  nextFrontendApi: typeof nextFrontendApi;
  pimApi: PimApi;
  pricesApi: PricesApi;
  productConfiguratorSearchApi: ProductConfiguratorSearchApi;
  recommendationsApi: RecommendationsApi;
  searchApi: SearchApi;
};

export const ApiClientsContext = createContext<ApiClients | undefined>(
  undefined
);

export const useApiClients = () => {
  const context = useContext(ApiClientsContext);

  if (context === undefined) {
    throw Error("useApiClients must be used within its provider.");
  }

  return context;
};

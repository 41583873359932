import React from "react";
import type { ProductData } from "@xxl/frontend-api";
import { VariantServiceProductDumb } from "./VariantServiceProductDumb";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { SERVICES, useServicesContext } from "../ServiceProductsContext";
import { useSharedData } from "../../../contexts/SharedData";
import {
  addServiceToEgCart,
  SUCCESS_RESPONSE_STATUS,
} from "../../../components/Cart/Api/CartAPI";
import {
  SET_CART_COUNT,
  useCartContext,
} from "../../../components/Cart/CartState";
import {
  getAddedServiceId,
  getAddedServiceItemId,
  getAddedServiceQuantity,
} from "../serviceProductsHelper";
import type { GenericGraphQLError } from "../../../components/Cart/Api/types";
import { XxlButton } from "../../../components/Common/XxlButton";
import { XxlStack } from "../../../components/Common/XxlStack";
import { useBreakpoint } from "../../../hooks/useBreakpoint/useBreakpoint";

type Props = {
  item: ProductData;
  actionInProgress: boolean;
  setActionInProgress: (isInProgress: boolean) => void;
  onClose: () => void;
  serviceTextList: string[];
};

export const VariantServiceProduct = ({
  item,
  actionInProgress,
  setActionInProgress,
  onClose,
  serviceTextList,
}: Props) => {
  const { t } = useTranslations();
  const bp = useBreakpoint();
  const isMobile = bp === "mobile";
  const { configuration } = useSharedData().data;
  const { state, dispatch } = useServicesContext();
  const { isProductPage } = state;
  const { dispatch: cartDispatch } = useCartContext();
  const { description, name, price, brand, code } = item;
  if (
    name === undefined ||
    price === undefined ||
    brand === undefined ||
    code === undefined ||
    description === undefined
  ) {
    return null;
  }

  const handleAddToCartClick = async () => {
    const shouldReturnEarly = [
      item.sizeOptions === undefined,
      item.sizeOptions?.length === 0,
      actionInProgress,
    ].some(Boolean);

    if (shouldReturnEarly) {
      return;
    }

    setActionInProgress(true);
    const eanCode = item.ean !== undefined ? item.ean[0] : "";
    const response = await addServiceToEgCart(
      eanCode,
      "1",
      state.productCartEntryId,
      configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
      configuration.amplifyConfig.aws_appsync_apiKey
    );
    const { status, data } = response;

    if (status === SUCCESS_RESPONSE_STATUS) {
      setActionInProgress(false);
      onClose();
      cartDispatch({
        type: SET_CART_COUNT,
        payload: {
          count: data.data?.addServiceProductsToCartItems.totals
            .itemsCount as number,
        },
      });
      setTimeout(() => {
        if (isProductPage) {
          dispatch({
            type: SERVICES.ADDED,
            payload: {
              serviceAdded: eanCode,
              serviceEntryNumber: getAddedServiceId(
                data.data?.addServiceProductsToCartItems.items,
                item.ean,
                window.xxlServiceProducts.cartEntryItemId
              ),
              parentId: getAddedServiceItemId(
                data.data?.addServiceProductsToCartItems.items,
                item.ean,
                window.xxlServiceProducts.cartEntryItemId
              ),
              addedServiceQuantity: getAddedServiceQuantity(
                data.data?.addServiceProductsToCartItems.items,
                item.ean,
                window.xxlServiceProducts.cartEntryItemId
              ),
            },
          });
        }
      }, 1000);
    } else if (response.data.errors !== undefined) {
      const { errors } = response.data;
      dispatch({
        type: SERVICES.HAS_ERROR,
        payload: errors as GenericGraphQLError[],
      });
      setActionInProgress(false);
    }
  };

  return (
    <VariantServiceProductDumb
      brand={brand}
      code={code}
      isProductPage={isProductPage}
      name={name}
      price={price}
      serviceTextList={serviceTextList}
    >
      <XxlButton
        variant="secondary"
        onClick={() => void handleAddToCartClick()}
        disabled={actionInProgress}
        loading={actionInProgress}
        data-testid="add-to-cart-button"
      >
        {t("services.popup.add.service.label")}
      </XxlButton>
      <XxlStack
        pt={"12px"}
        sx={{
          display: !isMobile ? "inline" : "none",
        }}
      >
        <XxlButton
          variant="outlinedTransparent"
          onClick={onClose}
          disabled={actionInProgress}
        >
          {t("sorts.close.button.text")}
        </XxlButton>
      </XxlStack>
    </VariantServiceProductDumb>
  );
};

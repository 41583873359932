import type * as CSSType from "csstype";
import React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { xxlTheme } from "../../../styles/xxl-theme";
import type { TranslationKey } from "../../../translations";
import type { BadgeType } from "../../../utils/data-types";
import type { RibbonDirection } from "../NewRibbon/NewRibbon";
import { NewRibbon } from "../NewRibbon/NewRibbon";

const { colors } = xxlTheme;

type Badge = {
  backgroundColour: CSSType.Property.BackgroundColor;
  foregroundColour: CSSType.Property.Color;
  name: TranslationKey;
};

type BadgeTheme = {
  [key in BadgeType]: {
    backgroundColour: CSSType.Property.BackgroundColor;
    foregroundColour: CSSType.Property.Color;
    name: TranslationKey;
  };
};

const BadgeColorTheme: BadgeTheme = {
  ALMOST_SOLD_OUT: {
    backgroundColour: colors.xxlAmber,
    foregroundColour: colors.xxlBlack,
    name: "badge.almost.sold.out",
  },
  BEST_SELLER: {
    backgroundColour: colors.xxlTurquoise,
    foregroundColour: colors.xxlBlack,
    name: "badge.best.seller",
  },
  NEW: {
    backgroundColour: colors.xxlAmber,
    foregroundColour: colors.xxlBlack,
    name: "badge.new",
  },
  ONLINE: {
    backgroundColour: colors.xxlBlack,
    foregroundColour: colors.xxlWhite,
    name: "product.available.online",
  },
  POPULAR: {
    backgroundColour: colors.xxlTurquoise,
    foregroundColour: colors.xxlBlack,
    name: "badge.popular",
  },
  REWARD: {
    backgroundColour: colors.xxlGreen,
    foregroundColour: colors.xxlBlack,
    name: "badge.reward",
  },
  STORE: {
    backgroundColour: colors.xxlWhite,
    foregroundColour: colors.xxlBlack,
    name: "product.available.store",
  },
  XXL_RECOMMENDS: {
    backgroundColour: colors.xxlGreen,
    foregroundColour: colors.xxlBlack,
    name: "badge.xxl.recommends",
  },
  PRESALE: {
    backgroundColour: colors.xxlAmber,
    foregroundColour: colors.xxlBlack,
    name: "badge.presale",
  },
} as const;

type BadgeProps = {
  badgeType: BadgeType;
  direction?: RibbonDirection;
};

export const ProductBadge = ({
  badgeType,
  direction = "right",
}: BadgeProps) => {
  const { t } = useTranslations();
  const badge: Badge = BadgeColorTheme[badgeType];

  return (
    <NewRibbon colorTheme={badge} label={t(badge.name)} direction={direction} />
  );
};

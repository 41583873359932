import React from "react";
import type { FunctionComponent, PropsWithChildren } from "react";
import type { ApiClients } from "../../../contexts/ApiClients";
import { ApiClientsContext } from "../../../contexts/ApiClients";
import { useSharedData } from "../../../contexts/SharedData";
import {
  Configuration as DeliveryStreamApiConfig,
  DeliverystreamApi,
} from "@xxl/deliverystream-api";
import {
  AuthApi,
  Configuration as FrontendApiConfiguration,
  PricesApi,
} from "@xxl/frontend-api";
import { Configuration as PimApiConfig, PimApi } from "@xxl/pim-api";
import {
  CalculateApi,
  Configuration as ProductConfiguratorApiConfiguration,
  SearchApi as ProductConfiguratorSearchApi,
} from "@xxl/product-configurator-api";
import {
  ContentApi,
  Configuration as ContentApiConfiguration,
} from "@xxl/content-api";
import {
  RecommendationsApi,
  Configuration as RecommendationsApiConfig,
} from "@xxl/recommendations-api";
import {
  Configuration as CustomersApiConfig,
  CustomersApi,
} from "@xxl/customers-api";
import { Configuration as SearchApiConfig, SearchApi } from "@xxl/search-api";
import { nextFrontendApi } from "../../../api/NextFrontendApi";
import { ElevateApi } from "@xxl/product-search-api";

const ApiClientsProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const {
    contentApi,
    customersApi,
    deliveryStreamApi,
    elevateApi,
    elevateClusterApi,
    frontendApi,
    pimApi,
    productConfiguratorApi,
    recommendationsApi,
    searchApi,
  } = useSharedData().data.configuration;

  const basePath =
    typeof window !== "undefined"
      ? window.location.origin
      : frontendApi.basePath;

  const apis = React.useMemo<ApiClients>(
    () => ({
      authApi: new AuthApi(
        new FrontendApiConfiguration({ ...frontendApi, basePath })
      ),
      calculateApi: new CalculateApi(
        new ProductConfiguratorApiConfiguration(productConfiguratorApi)
      ),
      contentApi: new ContentApi(new ContentApiConfiguration(contentApi)),
      customersApi: new CustomersApi(new CustomersApiConfig(customersApi)),
      deliveryStreamApi: new DeliverystreamApi(
        new DeliveryStreamApiConfig(deliveryStreamApi)
      ),
      elevateApi: ElevateApi.getInstance(elevateApi),
      elevateClusterApi: ElevateApi.getInstance(elevateClusterApi),
      nextFrontendApi,
      pimApi: new PimApi(new PimApiConfig(pimApi)),
      pricesApi: new PricesApi(
        new FrontendApiConfiguration({ ...frontendApi, basePath })
      ),
      productConfiguratorSearchApi: new ProductConfiguratorSearchApi(
        new ProductConfiguratorApiConfiguration(productConfiguratorApi)
      ),
      recommendationsApi: new RecommendationsApi(
        new RecommendationsApiConfig(recommendationsApi)
      ),
      searchApi: new SearchApi(new SearchApiConfig(searchApi)),
    }),
    [
      contentApi,
      customersApi,
      deliveryStreamApi,
      elevateApi,
      frontendApi,
      pimApi,
      productConfiguratorApi,
      recommendationsApi,
      searchApi,
    ]
  );

  return (
    <ApiClientsContext.Provider value={apis}>
      {children}
    </ApiClientsContext.Provider>
  );
};

export { ApiClientsProvider };

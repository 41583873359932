import type { ImageData } from "@xxl/frontend-api";
import type { Image } from "@xxl/content-api";
import isUndefined from "lodash/isUndefined";
import { MISSING_PRODUCT_IMAGE_URL } from "../constants";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const getBaseUrl = (imageData: ImageData | Image): string => {
  if ("baseUrl" in imageData && !isUndefined(imageData.baseUrl)) {
    return imageData.baseUrl;
  }
  if ("url" in imageData && !isUndefined(imageData.url)) {
    return imageData.url;
  }
  return "";
};

/**
 * Returns an image URL from an ImageData object (Sanity hosted)
 * Automatically applies any provided Hotspot
 * Optionally restricts width
 * @param imageData ImageData|Image
 * @param width? Max width in pixels
 */
//
const getImageUrl = (
  imageData: ImageData | Image,
  width?: number,
  height?: number
): string => {
  const { hotspot = null } = imageData;
  const baseUrl = getBaseUrl(imageData);
  let url = `${baseUrl}?auto=format&fit=crop&q=100`;

  if (!isUndefined(width)) {
    url += `&w=${width}`;
  }
  if (!isUndefined(height)) {
    url += `&h=${height}`;
  }
  if (isNotNullOrUndefined(hotspot)) {
    const { x, y } = hotspot;
    if (!isUndefined(x)) {
      url += `&fp-x=${x}`;
    }
    if (!isUndefined(y)) {
      url += `&fp-y=${y}`;
    }
  }
  return url;
};

const handleError = (event: React.InvalidEvent<HTMLImageElement>) => {
  event.target.src = `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
};
export const getHotspot = (image: Image) =>
  isNotNullOrUndefined(image.hotspot)
    ? {
        x: image.hotspot.x ?? 0,
        y: image.hotspot.y ?? 0,
      }
    : undefined;

export { getBaseUrl, getImageUrl, handleError };

import { Close, Info } from "@xxl/icons";
import React, { useCallback, useEffect, useState } from "react";
import { CART_BASE_PATH } from "../../constants";
import { useSharedData } from "../../contexts/SharedData";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import {
  CloseButton,
  CrossSalesFooter,
  CrossSalesGridContent,
  CrossSalesHeader,
  CrossSalesSubheader,
  CrossSalesWarning,
  FreeDeliveryWrapper,
  HeaderText,
} from "./CrossSales.styled";
import { CrossSalesProduct } from "./CrossSalesProduct";
import { DialogBox } from "../DialogBox";
import { HeaderProduct } from "./HeaderProduct";
import type { AddProductsToCartMutation } from "../../generated/graphql-code-generator";
import FreeDeliveryProgress from "../FreeDeliveryProgress";
import { Skeleton } from "@mui/material";
import type {
  FreeDelivery,
  CrossSalesProduct as CSP,
} from "./CrossSales.helper";
import { siteIdToPostalCode } from "./CrossSales.helper";
import { legacySiteUidToSiteUid } from "../../utils/xxl-shared-data";
import { getCart } from "../Cart/Api/CartAPI";
import {
  ShipmentsApi,
  Configuration as ShipmentsApiConf,
} from "@xxl/shipments-api";
import { useTracking } from "../../contexts/Tracking";
import type { ProductType } from "@xxl/pim-api";
import { log } from "@xxl/logging-utils";

export type ClickAndCollectWarningProps = Pick<
  NonNullable<
    Pick<
      Pick<
        Pick<
          AddProductsToCartMutation,
          "addProductsToCart"
        >["addProductsToCart"],
        "items"
      >["items"][0],
      "collectStore"
    >["collectStore"]
  >,
  "warning"
>["warning"];

export type ProductAddedToCartData = {
  brand: string;
  imageUrl: string;
  name: string;
  productType?: ProductType;
  salesPrice: number;
  salesPriceFormatted: string;
  size: string;
};

type CrossSalesProps = {
  onClickClose: () => void;
  productData: ProductAddedToCartData;
  crossSalesProducts: CSP[];
  clickAndCollectWarning: ClickAndCollectWarningProps;
};

const CrossSales: React.FunctionComponent<CrossSalesProps> = ({
  onClickClose,
  productData,
  crossSalesProducts,
  clickAndCollectWarning,
}) => {
  const {
    configuration,
    featureToggles: { toggle_free_delivery_widget, toggle_cross_sales },
    siteUid,
  } = useSharedData().data;
  const { t } = useTranslations();
  const { sendCrossSalesCloseEvent, sendCrossSalesCheckoutEvent } =
    useTracking();
  const [shipmentsApi] = useState(
    new ShipmentsApi(new ShipmentsApiConf(configuration.shipmentsApi))
  );
  const [freeDeliveryData, setFreeDeliveryData] = useState<FreeDelivery | null>(
    null
  );

  const updateFreeDeliveryStatus = async () => {
    try {
      const cartResponseData = (
        await getCart(
          configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
          configuration.amplifyConfig.aws_appsync_apiKey
        )
      ).data;

      const { cart } = cartResponseData.data ?? {};

      if (cart === undefined || cart === null) {
        log.error("Expecting cart to be set.", cart);
        return;
      }

      const {
        id: { id },
      } = cart;

      const siteId = legacySiteUidToSiteUid(siteUid);
      const response = await shipmentsApi.freeDelivery(
        siteId,
        siteIdToPostalCode(siteId),
        {
          cartGUID: id,
          userId: "anonymous",
        }
      );

      const { eligibleForFreeDelivery, cartTotal, freeDeliveryThreshold } =
        response.data;

      if (eligibleForFreeDelivery === false) {
        setFreeDeliveryData({
          isEligible: false,
        });
        return;
      }

      if (
        eligibleForFreeDelivery === undefined ||
        cartTotal === undefined ||
        freeDeliveryThreshold === undefined
      ) {
        log.error(
          "Free delivery properties cannot be undefined",
          eligibleForFreeDelivery,
          cartTotal,
          freeDeliveryThreshold
        );
        return;
      }

      setFreeDeliveryData({
        isEligible: eligibleForFreeDelivery,
        cartTotal,
        threshold: freeDeliveryThreshold,
      });
    } catch (error) {
      log.error("Failed to get free delivery data.", error);
      setFreeDeliveryData(null);
    }
  };

  useEffect(() => {
    if (!toggle_free_delivery_widget) {
      return;
    }

    void updateFreeDeliveryStatus();
  }, []);

  const closeCrossSales = useCallback(() => {
    sendCrossSalesCloseEvent();
    onClickClose();
  }, []);

  if (!toggle_cross_sales) {
    return null;
  }

  return (
    <DialogBox
      isDialogBoxOpen={true}
      dialogBoxSize="md"
      hasPadding={false}
      hideDefaultCloseButton={true}
      contentStyle={{ height: "100%" }}
    >
      <CrossSalesHeader>
        <HeaderText>{t("cart.notification.item.added")}</HeaderText>
        <CloseButton onClick={closeCrossSales} type="button">
          <Close />
        </CloseButton>
      </CrossSalesHeader>
      <HeaderProduct product={productData} />
      {clickAndCollectWarning !== null && (
        <CrossSalesWarning>
          <Info />
          {t("product.details.add.to.cart.warning.mixed.cart.stock")}
        </CrossSalesWarning>
      )}

      {toggle_free_delivery_widget && (
        <>
          {freeDeliveryData !== null ? (
            freeDeliveryData.isEligible && (
              <FreeDeliveryWrapper>
                <FreeDeliveryProgress
                  cartPrice={freeDeliveryData.cartTotal}
                  freeShippingThreshold={freeDeliveryData.threshold}
                />
              </FreeDeliveryWrapper>
            )
          ) : (
            <FreeDeliveryWrapper>
              <Skeleton variant="rectangular" width={"100%"}>
                <FreeDeliveryProgress cartPrice={0} freeShippingThreshold={0} />
              </Skeleton>
            </FreeDeliveryWrapper>
          )}
        </>
      )}

      <CrossSalesSubheader>{t("crossSales.subheader")}</CrossSalesSubheader>

      <CrossSalesGridContent>
        {crossSalesProducts.map((crossSalesProduct, idx) => (
          <CrossSalesProduct
            product={crossSalesProduct}
            idx={idx}
            key={`cross-sales-product-${
              crossSalesProduct.baseProductCode ?? idx
            }`}
            onProductAddedToCart={() => void updateFreeDeliveryStatus()}
          />
        ))}
      </CrossSalesGridContent>

      <CrossSalesFooter>
        <button
          type="button"
          onClick={closeCrossSales}
          className="button button--large button--outlined button--secondary"
        >
          {t("product.details.clickcollect.continue.shopping")}
        </button>
        <a
          href={CART_BASE_PATH}
          onClick={sendCrossSalesCheckoutEvent}
          data-testid="cross-sales-go-to-checkout"
          className="button button--large button--primary"
        >
          {t("cart.go.to.checkout")}
        </a>
      </CrossSalesFooter>
    </DialogBox>
  );
};

export { CrossSales };
